import cx from 'classnames';
import { cloneDeep, filter, forEach, includes, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { memo, useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import * as agencyActions from '../../actions/agency';
import { getAgentSignature } from '../../actions/completion';
import * as connections from '../../actions/connections';
import * as dashboard from '../../actions/dashboard';
import { loadDocumentList } from '../../actions/dashboard';
import { openDocumentForm } from '../../actions/document';
import { updateTeamMemberViewing, updateTeamMemberViewingState } from '../../actions/user';
import ModalDialog from '../../common/components/ModalDialog';
import MultiSelect from '../../common/components/MultiSelect';
import Pagination from '../../common/components/Pagination';
import DocumentStatusToolbar from '../../components/dashboard/documentList/DocumentStatusToolbar';
import DocumentTable from '../../components/dashboard/documentList/DocumentTable';
import EmptyDocState from '../../components/dashboard/documentList/EmptyDocState';
import { isAdmin, isTeamMember } from '../../utils/userUtils';
import styles from './DocumentList.module.scss';

import { PictureAsPdfSharp } from '@flk-mui-icons';
import AnnouncementSharpIcon from '@flk-mui-icons/AnnouncementSharp';
import DescriptionSharpIcon from '@flk-mui-icons/DescriptionSharp';
import HowToRegSharpIcon from '@flk-mui-icons/HowToRegSharp';
import TrendingDownIcon from '@flk-mui-icons/TrendingDownSharp';
import TrendingUpIcon from '@flk-mui-icons/TrendingUpSharp';
import axios from 'axios';
import {
    documentListReset,
    removeDocumentInList,
    updateDocumentProgress,
    updateReloadDocumentList
} from '../../actions/document';
import Button from '../../common/components/Button';
import Dashboard from '../../common/components/dashboard/Dashboard';
import DashboardHeader from '../../common/components/dashboard/DashboardHeader';
import TerminationNotice from '../../common/components/icons/TerminationNotice';
import { LargeButton } from '../../components/dashboard/documentList/components/LargeButton';
import {
    ALL,
    BREACH_NOTICE,
    DEFAULT_PAGE,
    DEFAULT_RECORDS_PER_PAGE,
    DISCLOSURE_DOCUMENT,
    DOCUMENT_CREATE_A_FLK,
    DOCUMENT_CREATE_MODE,
    DOCUMENT_CUSTOM,
    DOCUMENT_ESTIMATED_SELLING_PRICE_NOTICE,
    DOCUMENT_INTENTION_TO_SELL,
    DOCUMENT_RENT_INCREASE,
    DOCUMENT_RENT_REDUCTION,
    DOCUMENT_TERMINATION_NOTICE,
    ENTRY_NOTICE,
    HIDE_MESSAGE_TIME,
    isMobileWidth
} from '../../config';
import { UAD_MOBILE_WIDTH } from '../../constants/constants';
import { FLK_B_MOBILE_RESPONSIVE, UPLOAD_A_DOC_RESPONSIVE } from '../../constants/featureFlags';
import { useDocumentAdded } from '../../hooks/useDocumentAdded';
import { useDocumentRemoved } from '../../hooks/useDocumentRemoved';
import { useDocumentUpdate } from '../../hooks/useDocumentUpdate';
import { getTeam, getUserAgency } from '../../selectors/agency';
import { getReloadDocumentList } from '../../selectors/dashboard';
import { getDocPageNav, getDocumentList, getHasDocuments, getIsPending } from '../../selectors/dashboard/documentList';
import { getDocsListChange } from '../../selectors/document';
import { getSavedTeamMemberSelection, getUserInfo } from '../../selectors/user';
import { isDocTypeALL, isDocTypeCreateAFlk, isDocTypeFlkAPdf } from '../../utils/agreementUtils';
import { usePrevious } from '../../utils/reactUtils';

import { ReactComponent as EmailPdfImage } from '../../../assets/images/icons/email-pdf.svg';
import { ReactComponent as PlusIcon } from '../../../assets/images/icons/plus-rounded-blue.svg';

import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';
import Icon, { Icons } from '../../common/components/Icon';
import useOpenCreationModal from '../../hooks/useOpenCreationModal';
import DocumentDrop from '../shared/DocumentDrop';

const DocumentList = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const savedDataRef = useRef(null);

    const [selected, setSelected] = useState([]);
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [uniqueEmail, setUniqueEmail] = useState(false);
    const [uniqueEmailBusy, setUniqueEmailBusy] = useState(false);
    const [showCopySuccessful, setShowCopySuccessful] = useState(false);
    const isPending = useSelector(getIsPending);
    const docsListChange = useSelector(getDocsListChange);
    const { docStatus, docType } = useParams();
    const docPageNav = useSelector(getDocPageNav);
    const userInfo = useSelector(getUserInfo);
    const documentList = useSelector(getDocumentList);
    const hasDocuments = useSelector(getHasDocuments);
    const agentSignature = useSelector(state => state.completion.agentSignature, shallowEqual);
    const agency = useSelector(getUserAgency);
    const initialDocumentListRequested = useSelector(
        state => state.dashboard.initialDocumentListRequested,
        shallowEqual
    );
    const team = useSelector(getTeam);
    const savedTeamMemberSelection = useSelector(getSavedTeamMemberSelection);
    const reloadDocumentList = useSelector(getReloadDocumentList);
    const isFlkBMobileResponsiveEnabled = useFeatureFlag(FLK_B_MOBILE_RESPONSIVE);
    const isUploadADocResponsiveEnabled = useFeatureFlag(UPLOAD_A_DOC_RESPONSIVE);

    const isMobileView =
        isFlkBMobileResponsiveEnabled && isUploadADocResponsiveEnabled && isMobileWidth(UAD_MOBILE_WIDTH);

    const prevDocStatus = usePrevious(docStatus);

    // we need to save this inside a ref. otherwise Twilio events get old status
    savedDataRef.current = { docStatus, docType };

    useEffect(() => {
        // this logic is to check whether we have a query parameter e.g. ?create=DOCUMENT_EXAMPLE_NAME in the url
        // we need this for Create a FLK and FLK a PDF
        // it allows us to navigate directly to the creation modal / refresh the page and stay on the creation modal
        const queryParam = history.location.search.split('=');

        if (queryParam && queryParam.length === 2 && queryParam[0] === '?create') {
            dispatch(openDocumentForm(queryParam[1], null, DOCUMENT_CREATE_MODE));
        }
    }, [history.location.search, dispatch]);

    function delay(time) {
        return new Promise(resolve => setTimeout(resolve, time));
    }

    useEffect(() => {
        if (showCopySuccessful) {
            delay(HIDE_MESSAGE_TIME).then(() => {
                setShowCopySuccessful(false);
            });
        }
    }, [showCopySuccessful]);

    /**
     * Hook to listen to new documents within an agency
     */
    useDocumentAdded(agency, item => {
        // if document agent is same as logged in user then this doc should be added to redux. therefor no need to refresh
        if (userInfo && userInfo.agent && item.data.agent && userInfo.agent.id === item.data.agent.id) {
            const { docType, docStatus } = savedDataRef.current;
            //Refresh doc list
            const newPageNav = getPaginationByStatus(docStatus);
            if (
                (docType === item.data.docType ||
                    (docType === ALL && [DOCUMENT_CUSTOM, DOCUMENT_CREATE_A_FLK].includes(item.data.docType))) &&
                docStatus === item.data.status
            ) {
                dispatch(
                    loadDocumentList(
                        item.data.docType,
                        newPageNav.page,
                        newPageNav.recordsPerPage,
                        docStatus,
                        savedTeamMemberSelection
                    )
                );
            }
        }
    });

    /**
     * Hook to listen to updates of documents within an agency
     */
    useDocumentUpdate(userInfo.agency, item => {
        const { docType, docStatus } = savedDataRef.current;
        if (item.data && item.data.id) {
            if (
                item.data.docType === docType ||
                (docType === ALL && [DOCUMENT_CUSTOM, DOCUMENT_CREATE_A_FLK].includes(item.data.docType))
            ) {
                dispatch(updateDocumentProgress(item.data, docStatus, docType));
            }
        }
    });

    /**
     * Hook to listen to deletes of documents within an agency
     */
    useDocumentRemoved(userInfo.agency, item => {
        const { docType } = savedDataRef.current;
        if (item.previousItemData && item.previousItemData.id) {
            dispatch(removeDocumentInList(item.previousItemData, undefined, docType));
        }
    });

    useEffect(() => {
        dispatch(connections.getConnectionCompanies());
        dispatch(agencyActions.getAllTeamMembers());
        /**
         * Get agent signature on load
         */
        if (userInfo.id) {
            dispatch(getAgentSignature(userInfo.id));
            setSelected(userInfo.savedTeamMemberSelection);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, userInfo.id]);

    /**
     * Load all leases if docType changes or team member selection changes
     */
    useEffect(() => {
        if (!isEmpty(docType) && typeof savedTeamMemberSelection !== 'undefined') {
            const newPageNav = getPaginationByStatus(docStatus);
            loadAllDocuments(docType, docStatus, newPageNav, savedTeamMemberSelection);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [savedTeamMemberSelection, docType]);

    useEffect(() => {
        if (reloadDocumentList) {
            dispatch(
                loadDocumentList(
                    docType,
                    newPageNav.page,
                    newPageNav.recordsPerPage,
                    docStatus,
                    savedTeamMemberSelection
                )
            );
            dispatch(updateReloadDocumentList(false));
        }
    }, [reloadDocumentList]);

    useEffect(() => {
        if (docsListChange) {
            const newPageNav = getPaginationByStatus(docStatus);
            loadAllDocuments(docType, docStatus, newPageNav, savedTeamMemberSelection);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [docsListChange]);

    /**
     * Load specific document when status is changed
     */
    useEffect(() => {
        // if we do not have a prevDocStatus, that means this is loading the page first time. If so we do not need to get document by status
        if (prevDocStatus && docStatus) {
            let newPageNav = getPaginationByStatus(docStatus, docPageNav);
            loadDocumentsByStatus(
                docType,
                docStatus,
                savedTeamMemberSelection,
                newPageNav.page,
                newPageNav.recordsPerPage
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [docStatus]);

    /**
     * If refresh page was requested from the socket
     * Action is taken here
     */
    const loadAllDocuments = (docType, docStatus, allPageNav, teamMembersViewing = []) => {
        forEach(dashboard.agreementStatuses[docType], status => {
            let newPageNav = getPaginationByStatus(status, allPageNav);
            const clearList = true;
            if (isEmpty(teamMembersViewing)) {
                dispatch(loadDocumentList(docType, newPageNav.page, newPageNav.recordsPerPage, status, clearList));
            } else {
                dispatch(
                    loadDocumentList(
                        docType,
                        newPageNav.page,
                        newPageNav.recordsPerPage,
                        status,
                        teamMembersViewing,
                        clearList
                    )
                );
            }
            dispatch(documentListReset());
        });
    };

    const loadDocumentsByStatus = (
        docType,
        docStatus,
        teamMembersViewing = [],
        page = DEFAULT_PAGE,
        recordsPerPage = DEFAULT_RECORDS_PER_PAGE
    ) => {
        const clearList = false;
        if (isEmpty(teamMembersViewing)) {
            dispatch(dashboard.loadDocumentList(docType, page, recordsPerPage, docStatus, undefined, clearList));
        } else {
            dispatch(
                dashboard.loadDocumentList(docType, page, recordsPerPage, docStatus, teamMembersViewing, clearList)
            );
        }
    };

    const getPaginationByStatus = (status, allPagination = docPageNav) => {
        return status in allPagination && allPagination[status]
            ? allPagination[status]
            : { page: DEFAULT_PAGE, recordsPerPage: DEFAULT_RECORDS_PER_PAGE };
    };

    const changePage = page => {
        const newPageNav = getPaginationByStatus(docStatus);
        loadDocumentsByStatus(docType, docStatus, savedTeamMemberSelection, page, newPageNav.recordsPerPage);
    };

    const handleMultiSelection = teamMembers => {
        setSelected(teamMembers);

        let newPageNav = cloneDeep(docPageNav);
        newPageNav[docStatus].page = 1;

        dispatch(dashboard.updateDocPageNav(newPageNav));
        dispatch(updateTeamMemberViewingState(teamMembers)).then(() => {
            dispatch(updateTeamMemberViewing(teamMembers));
        });
    };

    const customSelectionBoxHeader = selected => {
        let teamMemberNumber = selected.length;
        if (teamMemberNumber > 1) {
            return teamMemberNumber + ' team members selected';
        } else if (teamMemberNumber > 0) {
            return teamMemberNumber + ' team member selected';
        }
        return 'Select team members';
    };

    /**
     * Check whether the user has permission to view others' leases
     *
     * @return {boolean}
     */
    const canViewOthersAgreements = () => {
        const hasPermissionTo = userInfo.hasPermissionTo;
        if (isAdmin(userInfo.role)) {
            return true;
        } else {
            return !isEmpty(hasPermissionTo);
        }
    };

    /**
     * get the other agents' names that the user has permission to
     *
     * @return {array}
     */
    const getAgentNamesByIds = () => {
        const hasPermissionTo = userInfo.hasPermissionTo;

        let permittedAgentsList;

        if (isAdmin(userInfo.role)) {
            permittedAgentsList = team;
        } else {
            permittedAgentsList = filter(team, teamMember => {
                return includes(hasPermissionTo, teamMember.id);
            });
            //Add the loggedIn agent to the list
            if (team && team.length > 0) {
                const member = team.find(currentMember => currentMember.id === userInfo.id);
                if (member) {
                    permittedAgentsList.unshift(member);
                }
            }
        }

        return permittedAgentsList.map(value => {
            return {
                value: value.id,
                label: value.firstName + ' ' + value.secondName
            };
        });
    };

    const options = getAgentNamesByIds();
    const enableTeamViewing = canViewOthersAgreements();
    let newPageNav = getPaginationByStatus(docStatus);
    let pagination = (
        <div className="wrapper-pagination">
            <Pagination pageNav={newPageNav} onChange={e => changePage(e)} />
            {newPageNav.recordsPerPage < newPageNav.totalItemsCount ? (
                <p className="pagination-text desktop-only">
                    Page {newPageNav.page} of {Math.ceil(newPageNav.totalItemsCount / newPageNav.recordsPerPage)}
                </p>
            ) : (
                <p />
            )}
        </div>
    );

    const getIconByDocType = docType => {
        switch (docType) {
            case DOCUMENT_RENT_REDUCTION:
                return <TrendingDownIcon />;
            case DOCUMENT_RENT_INCREASE:
                return <TrendingUpIcon />;
            case DOCUMENT_CUSTOM:
                return <PictureAsPdfSharp />;
            case DOCUMENT_CREATE_A_FLK:
                return <DescriptionSharpIcon />;
            case DOCUMENT_TERMINATION_NOTICE:
                return <TerminationNotice />;
            case BREACH_NOTICE:
                return <AnnouncementSharpIcon />;
            case DISCLOSURE_DOCUMENT:
                return <HowToRegSharpIcon />;
            case ENTRY_NOTICE:
                return <Icon icon={Icons.ENTRY_DOOR} />;
            case DOCUMENT_INTENTION_TO_SELL:
                return <Icon icon={Icons.MEGAPHONE} />;
            case DOCUMENT_ESTIMATED_SELLING_PRICE_NOTICE:
                return <Icon icon={Icons.MEGAPHONE} />;
        }
    };

    const getNameByDocType = docType => {
        switch (docType) {
            case DOCUMENT_RENT_REDUCTION:
                return 'Rent Relief';
            case DOCUMENT_RENT_INCREASE:
                return 'Rent Increase Notices';
            case DOCUMENT_CUSTOM:
                return 'Uploaded Docs';
            case DOCUMENT_CREATE_A_FLK:
                return 'Built Docs';
            case DOCUMENT_TERMINATION_NOTICE:
                return 'Termination Notices';
            case BREACH_NOTICE:
                return 'Breach Notices';
            case DISCLOSURE_DOCUMENT:
                return 'Disclosure Documents';
            case ENTRY_NOTICE:
                return 'Entry Notices';
            case DOCUMENT_INTENTION_TO_SELL:
                return 'Intention to Sell Notices';
            case DOCUMENT_ESTIMATED_SELLING_PRICE_NOTICE:
                return 'Estimated Selling Price Notices';
        }
    };

    function getUniqueEmail(showModal) {
        if (userInfo.uniqueEmail) {
            setUniqueEmailBusy(false);
            setUniqueEmail(userInfo.uniqueEmail);
            setShowEmailModal(showModal);
        } else {
            setUniqueEmailBusy(true);
            setShowEmailModal(showModal);
            axios.get(`/api/user/unique-email`).then(response => {
                setUniqueEmailBusy(false);
                setUniqueEmail(response.data.uniqueEmail);
            });
        }
    }

    function dropFile(files) {
        openDocumentCreationModal(DOCUMENT_CUSTOM, files);
    }

    const { openDocumentCreationModal, isLeaseTypeEnabled, isActiveUser } = useOpenCreationModal();

    const showUploadViaEmail = () => {
        if (docType !== DOCUMENT_CUSTOM) return false;

        if (!isAdmin(userInfo.role) && !isTeamMember(userInfo.role)) return false;

        return true;
    };

    return (
        <Dashboard
            header={
                <DashboardHeader
                    icon={getIconByDocType(docType)}
                    title={getNameByDocType(docType)}
                    selectAgent={
                        enableTeamViewing ? (
                            <MultiSelect
                                options={options}
                                selected={selected || []}
                                onSelectedChanged={handleMultiSelection}
                                selectAllLabel="All members"
                                valueRenderer={customSelectionBoxHeader}
                            />
                        ) : undefined
                    }
                    showEmail={
                        showUploadViaEmail() ? (
                            <React.Fragment>
                                <div className={styles.emailModalButton}>
                                    <Button onClick={() => getUniqueEmail(!showEmailModal)}>Upload via email</Button>
                                </div>
                            </React.Fragment>
                        ) : null
                    }
                />
            }
            tabBarProps={{
                tabBar: <DocumentStatusToolbar leaseType={docType} leaseStatus={docStatus} pagination={docPageNav} />,
                isPending: isPending
            }}
            body={
                <React.Fragment>
                    {initialDocumentListRequested &&
                        documentList &&
                        documentList[docType] &&
                        documentList[docType][docStatus] && (
                            <DocumentTable
                                docType={docType}
                                documentList={documentList}
                                agentSignature={agentSignature}
                                docStatus={docStatus}
                                setShowEmailModal={getUniqueEmail}
                            />
                        )}
                    {!isMobileView && initialDocumentListRequested && !isPending && !hasDocuments && (
                        <EmptyDocState type={docType} />
                    )}
                    {!isMobileView &&
                        initialDocumentListRequested &&
                        !isPending &&
                        (!documentList ||
                            (documentList &&
                                documentList[docType] &&
                                documentList[docType][docStatus] &&
                                documentList[docType][docStatus].length <= 3 && (
                                    <>
                                        {isDocTypeFlkAPdf(docType) && (
                                            <div className={styles.buttonContainer}>
                                                <DocumentDrop
                                                    className={cx(styles.singleContainer, styles.documentDrop)}
                                                    dropzoneClassName={styles.dropzone}
                                                    updateDocuments={files => {
                                                        dropFile(files);
                                                    }}
                                                    maxFileSize={100}
                                                    maxTotalSize={100}
                                                    dropzoneTitle="Upload a Doc"
                                                    rejectEncryptedPdfs
                                                    documentLeaseType={DOCUMENT_CUSTOM}
                                                />
                                            </div>
                                        )}
                                        {isDocTypeCreateAFlk(docType) && (
                                            <div className={styles.buttonContainer}>
                                                <LargeButton
                                                    className={styles.singleContainer}
                                                    onClick={() => {
                                                        if (
                                                            isLeaseTypeEnabled(DOCUMENT_CREATE_A_FLK) &&
                                                            isActiveUser()
                                                        ) {
                                                            openDocumentCreationModal(DOCUMENT_CREATE_A_FLK);
                                                        }
                                                    }}
                                                    icon={<PlusIcon className={styles.plusIcon} />}
                                                    title={'Build a doc'}
                                                    label={'Build an interactive document/contract from scratch'}
                                                ></LargeButton>
                                            </div>
                                        )}
                                        {isDocTypeALL(docType) && (
                                            <div className={styles.buttonContainerThree}>
                                                <LargeButton
                                                    onClick={() => {
                                                        if (
                                                            isLeaseTypeEnabled(DOCUMENT_CREATE_A_FLK) &&
                                                            isActiveUser()
                                                        ) {
                                                            openDocumentCreationModal(DOCUMENT_CREATE_A_FLK);
                                                        }
                                                    }}
                                                    icon={<PlusIcon className={styles.plusIcon} />}
                                                    title={'Build a doc'}
                                                    label={'Build an interactive document/contract from scratch'}
                                                ></LargeButton>
                                                <DocumentDrop
                                                    className={styles.documentDrop}
                                                    dropzoneClassName={styles.dropzone}
                                                    updateDocuments={files => {
                                                        dropFile(files);
                                                    }}
                                                    maxFileSize={100}
                                                    maxTotalSize={100}
                                                    dropzoneTitle="Upload a Doc"
                                                    rejectEncryptedPdfs
                                                    documentLeaseType={DOCUMENT_CUSTOM}
                                                />
                                                <LargeButton
                                                    onClick={getUniqueEmail}
                                                    icon={<EmailPdfImage />}
                                                    title={'Upload via email'}
                                                    label={`Email multiple PDF's directly to this dashboard`}
                                                ></LargeButton>
                                            </div>
                                        )}
                                    </>
                                )))}
                    <ModalDialog
                        isOpen={showEmailModal}
                        title="Upload via email"
                        className={styles.popupEmailModal}
                        closeModal={() => setShowEmailModal(false)}
                        modalPosition="top"
                    >
                        <div className={styles.popupEmailContainer}>
                            <>
                                <p>
                                    To Instantly upload PDF(s), forward emails with PDF(s) attached to the below email
                                    address. (if there are multiple PDFs attached, we will create multiple drafts for
                                    you).
                                </p>
                                <p>Your email address is:</p>
                                {uniqueEmailBusy && <div className={styles.linkSpinner} />}
                                {!uniqueEmailBusy && uniqueEmail && (
                                    <div className={styles.emailContainer}>
                                        <div className={styles.emailBox}>
                                            <p>{uniqueEmail}</p>
                                        </div>
                                        <Button
                                            className={cx({
                                                [styles.success]: showCopySuccessful
                                            })}
                                            onClick={() => {
                                                navigator.clipboard
                                                    .writeText(uniqueEmail)
                                                    .then(() => setShowCopySuccessful(true));
                                            }}
                                        >
                                            {showCopySuccessful ? 'Copied!' : 'Copy'}
                                        </Button>
                                    </div>
                                )}
                            </>
                        </div>
                    </ModalDialog>
                </React.Fragment>
            }
            pagination={pagination}
        />
    );
};

DocumentList.propTypes = {
    isOpenAgreementInfoModal: PropTypes.bool,
    isOpenLeaseAgreementForm: PropTypes.bool,
    isEditLeaseAgreementForm: PropTypes.bool,
    pageNav: PropTypes.object
};

export default memo(DocumentList);
