import React, { useState } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { useSelector, useDispatch } from 'react-redux';
import { map } from 'lodash';
import { clearAddressDetailsStore, openAddressDetailsModal } from '../../../actions/addressDetails';
import Button from '../../../common/components/Button';
import { SUB_LEASE_TYPE_SA_RENEWALS } from '../../../config';
import { SelectField } from '../../form/FormSelect';
import FormAddressDetailsAutocomplete from '../../form/FormAddressDetailsAutocomplete';
import { getAddressDetails } from '../../../selectors/lease/addresses';
import { getAllowedTemplateList } from '../../../selectors/dashboard';
import { getCreateLeaseError } from '../../../selectors/lease';
import {
    isLeaseTypeResidentialTenancy,
    isLeaseTypeCommercialLease,
    isLeaseTypeSales,
    isLeaseTypePm,
    mappingsToBackend
} from '../../../utils/agreementUtils';
import nswImage from '../../../../assets/images/content/NSW.svg';
import qldImage from '../../../../assets/images/content/QLD.svg';
import vicImage from '../../../../assets/images/content/VIC.svg';
import actImage from '../../../../assets/images/content/ACT.svg';
import saImage from '../../../../assets/images/content/SA.svg';
import NewLeaseReportData from '../new/NewLeaseReportData';
import { IntegrationSearchSelect } from '../../dashboard/integration/IntegrationSearchSelect';
import { getLabel } from '../../../utils/labelUtils';

import CreateNewLeaseTabs from '../CreateNewLeaseTabs';
import IntegrationAddressInput from '../IntegrationAddressInput';
import { ArrowDropDownSharp } from '@flk-mui-icons';
import SaRenewalSelect from './SaRenewalSelect';
import { formatDateStandard, getAgencyTimezoneFromUser } from '../../../utils/dateUtils';
import {
    getAgencyPropertyMeIntegration,
    getAgencyPropertyTreeIntegration,
    getAgencyManagedIntegration
} from '../../../selectors/agency';
import { getUserInfo } from '../../../selectors/user';

import styles from './DesktopView.module.scss';

const RENT_INCREASE_SELECT_BOX_OTHER_INDEX = 25;
const stateImagesMap = {
    NSW: nswImage,
    QLD: qldImage,
    VIC: vicImage,
    ACT: actImage,
    SA: saImage
};

function DesktopView({
    closeLeaseAgreementForm,
    submitForm,
    formData,
    changeForm,
    setShowStates,
    showStates,
    selectState,
    setFormData,
    setSelectedPropertyId,
    leaseType,
    cloneLeaseId,
    handleAddressChange,
    setAddressDetails,
    allowedStates,
    setActivePropertyType,
    activePropertyType,
    creatingLease
}) {
    const dispatch = useDispatch();

    const allowedTemplateList = useSelector(getAllowedTemplateList);
    const addressDetails = useSelector(getAddressDetails);
    const propertyMeIntegration = useSelector(getAgencyPropertyMeIntegration);
    const propertyTreeIntegration = useSelector(getAgencyPropertyTreeIntegration);
    const managedIntegration = useSelector(getAgencyManagedIntegration);
    const loggedInUser = useSelector(getUserInfo);
    const createLeaseError = useSelector(getCreateLeaseError);

    const [renewedFrom, setRenewedFrom] = useState(null);
    const [addressSelectedForRenewal, setAddressSelectedForRenewal] = useState(false);

    function toggleShowStates() {
        setShowStates(!showStates);
    }

    const setNumberOfBedrooms = numberOfBedrooms => {
        setFormData({
            ...formData,
            numberOfBedrooms
        });
    };

    const setNumberOfBathrooms = numberOfBathrooms => {
        setFormData({
            ...formData,
            numberOfBathrooms
        });
    };

    const setNumberOfCarSpaces = numberOfCarSpaces => {
        setFormData({
            ...formData,
            numberOfCarSpaces
        });
    };

    const setRentIncreaseAmount = rentIncreaseAmount => {
        setFormData({
            ...formData,
            rentIncreaseAmountIndex: rentIncreaseAmount,
            rentIncreaseAmount: rentIncreaseAmount === RENT_INCREASE_SELECT_BOX_OTHER_INDEX ? 0 : rentIncreaseAmount
            //If the rent increase selected is 25 which indicates index of Other we set to zero so user can override
        });
    };

    const handlePropertySelect = property => {
        // If property has leaseType property, that means this is a completed lease
        if (property?.leaseType) {
            setRenewedFrom(property);
        } else {
            // Currently propertyMe returns Id and propertyTree returns id, need to normalise it in the backend
            setSelectedPropertyId(property && (property.Id || property.id));
        }
    };

    function handleOnChangeAddress(address) {
        setFormData({
            ...formData,
            address
        });
    }

    function setIntegrationSearch(value) {
        setSelectedPropertyId(null);
        setFormData({
            ...formData,
            address: '',
            unit: '',
            street_number: '',
            route: '',
            locality: '',
            postal_code: '',
            administrative_area_level_1: '',
            political: '',
            isIntegrationSearch: value
        });
    }

    function getTitle(cloneLeaseId, leaseType) {
        if (leaseType === SUB_LEASE_TYPE_SA_RENEWALS) {
            return (
                <h1 className="create-newLease">Lease agreement renewal{addressSelectedForRenewal ? ' for' : ''}</h1>
            );
        }
        let action = cloneLeaseId ? 'Cloning' : 'Create new';
        let type;
        if (isLeaseTypeResidentialTenancy(leaseType)) {
            type = 'lease';
        } else if (isLeaseTypeCommercialLease(leaseType)) {
            type = 'commercial lease';
        } else {
            type = `${mappingsToBackend[leaseType]} agreement`;
        }
        return (
            <h1>
                {action} <span className="text">{type}</span> for
            </h1>
        );
    }

    const isIntegrationActive = propertyMeIntegration || propertyTreeIntegration || managedIntegration;
    const addressValue = formData.address;

    let disableButton = false;
    if (isLeaseTypeResidentialTenancy(leaseType)) {
        disableButton =
            !activePropertyType ||
            !formData.address ||
            !formData.numberOfBedrooms ||
            !formData.numberOfBathrooms ||
            !formData.numberOfCarSpaces ||
            (formData.isRentIncrease && !formData.rentIncreaseAmount);
    } else if (leaseType === SUB_LEASE_TYPE_SA_RENEWALS) {
        disableButton =
            !formData.address ||
            !formData.numberOfBedrooms ||
            !formData.numberOfBathrooms ||
            !formData.numberOfCarSpaces;
    } else {
        disableButton = !addressValue;
    }

    return (
        <div className="for-lg-modal">
            {getTitle(cloneLeaseId, leaseType)}
            <div className="create-newLease">
                <Form
                    onSubmit={values => submitForm({ ...values, renewedFrom: renewedFrom?.id })}
                    initialValues={formData}
                >
                    {({ handleSubmit, values, form }) => {
                        return (
                            <form onSubmit={handleSubmit} noValidate>
                                <FormSpy onChange={state => changeForm(state)} />
                                {(leaseType !== SUB_LEASE_TYPE_SA_RENEWALS ||
                                    (leaseType === SUB_LEASE_TYPE_SA_RENEWALS && !addressSelectedForRenewal)) && (
                                    <>
                                        {!cloneLeaseId && (
                                            <div className="column-box">
                                                <div className={`state-image`}>
                                                    <img src={stateImagesMap[values.location]} />
                                                </div>
                                                <div className="select-state-container">
                                                    <div className="selected-location">{values.location}</div>
                                                    {!isLeaseTypeCommercialLease(leaseType) && (
                                                        <button
                                                            type="button"
                                                            className="button-change-state"
                                                            onClick={() => toggleShowStates()}
                                                        >
                                                            Change state
                                                            <ArrowDropDownSharp className="Select-arrow" />
                                                        </button>
                                                    )}
                                                    {showStates && (
                                                        <div className={'showStates'}>
                                                            <div className="state-dropdown">
                                                                {map(allowedStates, state => {
                                                                    return (
                                                                        <p
                                                                            key={state}
                                                                            className="state-dropdown-item"
                                                                            onClick={() => {
                                                                                selectState(state);
                                                                            }}
                                                                        >
                                                                            {state}
                                                                        </p>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                        {isLeaseTypeCommercialLease(leaseType) && (
                                            <>
                                                <p>
                                                    This lease is to be used where the period is for 3 years or less
                                                    (including any option period) and to which the Retail Leases Act
                                                    1994 does not apply.
                                                </p>
                                                <br />
                                            </>
                                        )}

                                        {!cloneLeaseId &&
                                            isIntegrationActive &&
                                            (isLeaseTypeResidentialTenancy(leaseType) ||
                                                isLeaseTypeCommercialLease(leaseType) ||
                                                leaseType === SUB_LEASE_TYPE_SA_RENEWALS) && (
                                                <IntegrationSearchSelect
                                                    className={styles.integrationSearchSelect}
                                                    propertyMeIntegration={propertyMeIntegration}
                                                    propertyTreeIntegration={propertyTreeIntegration}
                                                    managedIntegration={managedIntegration}
                                                    setIntegrationSearch={value => setIntegrationSearch(value)}
                                                    isIntegrationSearchEnabled={values.isIntegrationSearch}
                                                />
                                            )}

                                        {values.isIntegrationSearch &&
                                        isIntegrationActive &&
                                        (isLeaseTypeResidentialTenancy(leaseType) ||
                                            isLeaseTypeCommercialLease(leaseType) ||
                                            leaseType === SUB_LEASE_TYPE_SA_RENEWALS) ? (
                                            <IntegrationAddressInput
                                                name="address"
                                                placeholder={cloneLeaseId ? 'Address for clone' : 'Address'}
                                                onChange={address => handleOnChangeAddress(address)}
                                                onSelect={handlePropertySelect}
                                                leaseType={leaseType}
                                                setRenewedFrom={setRenewedFrom}
                                                loggedInUser={loggedInUser}
                                            />
                                        ) : (
                                            <FormAddressDetailsAutocomplete
                                                name="address"
                                                isClone={!!cloneLeaseId}
                                                value={addressValue}
                                                editAddressFormData={addressDetails.editAddressFormData}
                                                editedNewAddress={addressDetails.selectedAddressEdited}
                                                editMode={addressDetails.editMode}
                                                showAddressBelow={false}
                                                detailsConfirmed={addressDetails.detailsConfirmed}
                                                label={cloneLeaseId ? 'Address for clone' : 'Address'}
                                                onChange={address => handleAddressChange(form.change, address)}
                                                setAddressDetails={(address, details) =>
                                                    setAddressDetails(address, details)
                                                }
                                                openAddressDetailsModal={data =>
                                                    dispatch(openAddressDetailsModal(data))
                                                }
                                                clearAddressDetailsStore={() => dispatch(clearAddressDetailsStore())}
                                                leaseType={leaseType}
                                                setRenewedFrom={setRenewedFrom}
                                            />
                                        )}

                                        {leaseType === SUB_LEASE_TYPE_SA_RENEWALS && renewedFrom && (
                                            <>
                                                <p>Linked to Residential Tenancy Agreement</p>
                                                <br />
                                                <p>{renewedFrom.address}</p>
                                                <small>
                                                    <i>Residential Tenancy</i>
                                                    <br />
                                                    <i>
                                                        {formatDateStandard(
                                                            renewedFrom.term.startDate,
                                                            getAgencyTimezoneFromUser(loggedInUser)
                                                        )}
                                                        -
                                                        {formatDateStandard(
                                                            renewedFrom.term.endDate,
                                                            getAgencyTimezoneFromUser(loggedInUser)
                                                        )}
                                                    </i>
                                                </small>
                                            </>
                                        )}

                                        {(isLeaseTypeSales(leaseType) || isLeaseTypePm(leaseType)) &&
                                            allowedTemplateList &&
                                            allowedTemplateList.length > 0 && (
                                                <div>
                                                    <SelectField
                                                        key={values.location}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                        name="template"
                                                        options={allowedTemplateList}
                                                        selectClassName="sel-4"
                                                        placeholder="Select template"
                                                        value={values.template}
                                                    />
                                                </div>
                                            )}

                                        {(isLeaseTypeResidentialTenancy(leaseType) ||
                                            leaseType === SUB_LEASE_TYPE_SA_RENEWALS) && (
                                            <CreateNewLeaseTabs
                                                active={activePropertyType}
                                                setActive={setActivePropertyType}
                                            />
                                        )}

                                        {(isLeaseTypeResidentialTenancy(leaseType) ||
                                            leaseType === SUB_LEASE_TYPE_SA_RENEWALS) && (
                                            <NewLeaseReportData
                                                leaseType={leaseType}
                                                values={values}
                                                isRentIncreaseOtherSelectedIndex={RENT_INCREASE_SELECT_BOX_OTHER_INDEX}
                                                setNumberOfBedrooms={setNumberOfBedrooms}
                                                setNumberOfBathrooms={setNumberOfBathrooms}
                                                setNumberOfCarSpaces={setNumberOfCarSpaces}
                                                setRentIncreaseAmount={setRentIncreaseAmount}
                                            />
                                        )}
                                    </>
                                )}

                                {leaseType === SUB_LEASE_TYPE_SA_RENEWALS && addressSelectedForRenewal && (
                                    <SaRenewalSelect addressValue={addressValue} values={values} />
                                )}

                                <div className="create-new-lease-button-container">
                                    <div className="button-cancel">
                                        <span onClick={closeLeaseAgreementForm}>Cancel</span>
                                    </div>
                                    {leaseType === SUB_LEASE_TYPE_SA_RENEWALS && !addressSelectedForRenewal && (
                                        <div className="button btn-spinner button-create-new-lease">
                                            <Button
                                                primary
                                                type="button"
                                                disabled={disableButton}
                                                onClick={() => {
                                                    setAddressSelectedForRenewal(true);
                                                }}
                                            >
                                                Create new draft
                                            </Button>
                                        </div>
                                    )}
                                    {(leaseType !== SUB_LEASE_TYPE_SA_RENEWALS || addressSelectedForRenewal) && (
                                        <div className="button btn-spinner button-create-new-lease">
                                            <Button primary type="submit" disabled={disableButton || creatingLease}>
                                                {creatingLease && <span className="savestatus-spinner-white" />}
                                                {getLabel('createLease', values.location, leaseType)}
                                            </Button>
                                        </div>
                                    )}
                                </div>
                                {createLeaseError && (
                                    <div className="text-danger">
                                        <br />
                                        Something went wrong when creating the{' '}
                                        {isLeaseTypeResidentialTenancy(leaseType) && 'lease'}
                                        {!isLeaseTypeResidentialTenancy(leaseType) && 'agreement'}, please try again or
                                        contact us via the live chat
                                    </div>
                                )}
                            </form>
                        );
                    }}
                </Form>
            </div>
        </div>
    );
}

export default DesktopView;
