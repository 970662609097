import React, { useEffect, useState } from 'react';
import { Table, Td, Th, Thead, Tr } from 'reactable';
import Pagination from 'react-js-pagination';
import axios from 'axios';
import PersonAddIcon from '@flk-mui-icons/PersonAddSharp';
import GetAppSharp from '@flk-mui-icons/GetAppSharp';
import { confirmAlert } from 'react-confirm-alert';
import UploadCsvModal from './UploadCsvModal';
import DeleteButton from '../../../common/components/DeleteButton';
import ViewButton from '../../../common/components/ViewButton';
import { cloneDeep, remove } from 'lodash';
import { useSelector } from 'react-redux';
import { getUserInfo } from '../../../selectors/user';
import { isAdmin } from '../../../utils/userUtils';
import { subscriptionStatus } from '../../../config';
import { InfoSharp } from '@flk-mui-icons';
import ReactTooltip from 'react-tooltip';

const SORT_OPTION_CREATED = 'created';
const SORT_OPTION_NAME = 'contactListName';

const PAGE_LIMIT = 10;

const ContactList = ({ contactType }) => {
    const loggedInUser = useSelector(getUserInfo);

    const [isOpenUploadModal, setIsOpenUploadModal] = useState(false);
    const [contactList, setContactList] = useState([]);
    const [sortOptions, setSortOptions] = useState({ [SORT_OPTION_CREATED]: 'asc' });
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const getListOfContactsList = (options = sortOptions, page = currentPage) => {
        setIsLoading(true);
        const field = Object.keys(options)[0];
        const direction = options[field];
        axios
            .get(`api/contacts/get-contacts-list`, {
                params: {
                    page,
                    limit: PAGE_LIMIT,
                    field,
                    direction,
                    contactType
                }
            })
            .then(response => {
                setContactList(response.data.contactList);
                setTotalPages(response.data.pages);
                setCurrentPage(response.data.page);
                setTotalItems(response.data.total);
                setIsLoading(false);
            })
            .catch(err => {
                setIsLoading(false);
                return false;
            });
    };

    useEffect(() => {
        setContactList([]);
        getListOfContactsList();
    }, [contactType]);

    const deleteContactList = item => {
        confirmAlert({
            title: '',
            message: 'Are you sure you want to delete this contact list?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        axios
                            .delete(`api/contacts/delete-contacts-list/${item._id}`)
                            .then(() => {
                                const list = cloneDeep(contactList);
                                remove(list, n => {
                                    return n._id === item._id;
                                });
                                setContactList(list);
                            })
                            .catch(err => {
                                return false;
                            });
                    }
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    };

    const addNewContactList = newItem => {
        const list = cloneDeep(contactList);
        list.unshift(newItem);
        setContactList(list);
    };

    const onChangeSort = item => {
        const oldSort = cloneDeep(sortOptions);
        let newSort = {};
        switch (oldSort[item]) {
            // if current sort option is 'asc' then next option is 'desc'
            case 'asc': {
                newSort[item] = 'desc';
                break;
            }
            // if current sort option is 'desc' then next option is no sort
            case 'desc': {
                newSort = { [SORT_OPTION_CREATED]: 'desc' };
                break;
            }
            // default means we do not have any sort option. then next sort option is 'asc'
            default: {
                newSort[item] = 'asc';
                break;
            }
        }

        setSortOptions(newSort);
        getListOfContactsList(newSort);
    };

    const getClassName = item => {
        let newClassName = 'no-sort';
        if (sortOptions[item] === 'asc') {
            newClassName = 'sort-asc';
        } else if (sortOptions[item] === 'desc') {
            newClassName = 'sort-desc';
        }
        return newClassName;
    };

    const changePage = page => {
        getListOfContactsList(sortOptions, page);
    };

    function downloadExampleCsv() {
        window.location.href = `${SERVICE_URL}/api/contacts/get-example-csv?contactType=${contactType}`;
    }

    return (
        <div className="team-users">
            {isLoading && <div className="blue-spinner" />}
            {!isLoading && !contactList.length && (
                <div className="add-users">
                    <p>You don’t have a contact list yet.</p>
                    <br />
                    <p className="special-link special-link-with-icon" onClick={downloadExampleCsv}>
                        <GetAppSharp />
                        Please click here to download an example CSV
                    </p>
                    <button
                        className="btn-add-user"
                        onClick={() => setIsOpenUploadModal(true)}
                        disabled={
                            !isAdmin(loggedInUser.role) ||
                            loggedInUser.agency.tariffPlan.status !== subscriptionStatus.ACTIVE
                        }
                    >
                        Upload csv
                    </button>
                    <ReactTooltip id="bulk-trial" class="tooltip" place="bottom" effect="solid" globalEventOff="wheel">
                        <p>
                            This feature is unavailable during your trial period. If you require assistance, please
                            contact Support via the live chat.
                        </p>
                    </ReactTooltip>
                    <div className="bulk-info-block text-gray">
                        <InfoSharp
                            className="tooltip-info-icon text-gray"
                            data-tip={loggedInUser.agency.tariffPlan.status === subscriptionStatus.TRIALING}
                            data-for={
                                loggedInUser.agency.tariffPlan.status === subscriptionStatus.TRIALING
                                    ? 'bulk-trial'
                                    : ''
                            }
                        />
                        <div>
                            <div>
                                Your Contact List can be utilised to send a FLK to a number of contacts using the bulk
                                document feature.
                            </div>
                            <div>
                                Please note, once you have reached your monthly limit, a charge of $0.50/SMS and
                                $0.10/email will be charged. Additional costs will require approval from your
                                administrator.
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {!isLoading && !!contactList.length && (
                <div className="team-block">
                    <div className="name">
                        <p>Contact List</p>
                        <div className="add-user">
                            <p onClick={downloadExampleCsv}>
                                <GetAppSharp />
                                Download Example CSV
                            </p>
                            <p
                                onClick={() => {
                                    setIsOpenUploadModal(true);
                                }}
                            >
                                <PersonAddIcon />
                                Upload csv
                            </p>
                        </div>
                    </div>
                    <div className="table-sort table">
                        <Table>
                            <Thead>
                                <Th column="name">
                                    <div
                                        className={`table-header-sort ${getClassName(SORT_OPTION_NAME)}`}
                                        onClick={() => onChangeSort(SORT_OPTION_NAME)}
                                    >
                                        <b>Contact list name</b>
                                    </div>
                                </Th>
                                <Th column="count">
                                    <b>No of contacts</b>
                                </Th>
                                <Th column="actions" className="actions">
                                    <b>Actions</b>
                                </Th>
                            </Thead>
                            {contactList.map((item, index) => (
                                <Tr key={index}>
                                    <Td column="name">
                                        <b className="capitalize">{item.contactListName}</b>
                                    </Td>
                                    <Td column="count">
                                        <b className="capitalize">{item.contactsCount}</b>
                                    </Td>
                                    <Td column="actions" className="actions">
                                        <div className="flex">
                                            <ViewButton
                                                onClick={() => {
                                                    window.open(`/contact-list/${item._id}`, '_blank');
                                                }}
                                                toolTip="View contacts"
                                                className="view-btn"
                                            />
                                            <DeleteButton
                                                onClick={() => {
                                                    deleteContactList(item);
                                                }}
                                                toolTip="Remove contacts list"
                                                className="delete-btn"
                                            />
                                        </div>
                                    </Td>
                                </Tr>
                            ))}
                        </Table>
                    </div>
                    <div className="wrapper-pagination">
                        <Pagination
                            hideDisabled
                            activePage={currentPage}
                            itemsCountPerPage={PAGE_LIMIT}
                            totalItemsCount={totalItems}
                            pageRangeDisplayed={3}
                            onChange={e => changePage(e)}
                            lastPageText={<div className="last-icon" />}
                            firstPageText={<div className="first-icon" />}
                            nextPageText="..."
                            prevPageText="..."
                        />
                        {PAGE_LIMIT < totalItems ? (
                            <p className="pagination-text">
                                Page {currentPage} of {totalPages}
                            </p>
                        ) : (
                            <p />
                        )}
                    </div>
                </div>
            )}
            <UploadCsvModal
                isOpen={isOpenUploadModal}
                closeUploadModal={() => setIsOpenUploadModal(false)}
                addNewContactList={addNewContactList}
                contactType={contactType}
            />
        </div>
    );
};

export default ContactList;
