import React from 'react';
import cx from 'classnames';
import { ExpandMore } from '@flk-mui-icons';

import ModalCreateNewAgreement from '../../../components/modals/ModalCreateNewAgreement.js';
import Button from '../Button.js';

import styles from './DashboardHeader.module.scss';

type DashboardHeaderProps = {
    icon: React.ReactNode;
    title: string;
    selectAgent?: React.ReactNode;
    manageTemplates?: React.ReactNode;
    showEmail?: React.ReactNode;
};

const DashboardHeader: React.FC<DashboardHeaderProps> = ({ icon, title, selectAgent, manageTemplates, showEmail }) => {
    const [isCreationModalOpen, setIsCreationModalOpen] = React.useState(false);

    return (
        <React.Fragment>
            <div className={styles.container}>
                {icon && <div className={styles.iconContainer}>{icon}</div>}
                <h1 className={styles.dashboardTitle}>{title}</h1>
                {selectAgent && <div className={styles.selectContainer}>{selectAgent}</div>}
                {manageTemplates && <div className={styles.manageTemplatesContainer}>{manageTemplates}</div>}
                {showEmail && <div className={styles.manageTemplatesContainer}>{showEmail}</div>}
                <Button
                    primary
                    className={cx(styles.createNewButton, isCreationModalOpen && styles.modalOpen)}
                    onClick={() => setIsCreationModalOpen(oldState => !oldState)}
                >
                    Create New
                    {<ExpandMore className={styles.createNewButtonIcon} />}
                </Button>
            </div>
            <ModalCreateNewAgreement isOpen={isCreationModalOpen} close={() => setIsCreationModalOpen(false)} />
        </React.Fragment>
    );
};

export default DashboardHeader;
