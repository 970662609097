import * as React from 'react';
import {
    TEMPLATE_TYPE_PROPERTY_MANAGEMENT,
    TEMPLATE_TYPE_SALES,
    CREATE_A_FLK_TEMPLATE,
    CREATE_A_FLK_DISPLAY
} from '../../config';
import '../../sass/modals/confirmUpdateTemplatesModal.scss';
import CheckCircleSharpIcon from '@flk-mui-icons/CheckCircleSharp';
import { forEach } from 'lodash';
import { camelcaseToSpacedWords } from '../../utils/generalUtils';

const BUTTON_TEXT_SETTINGS_ONLY = 'Settings only';
const BUTTON_TEXT_SETTINGS_AND_TEMPLATES = 'Settings & Templates';

function getTemplateTypeName(templateType) {
    switch (templateType) {
        case TEMPLATE_TYPE_PROPERTY_MANAGEMENT:
            return 'Property Management Agreement';
        case TEMPLATE_TYPE_SALES:
            return 'Sales';
        case CREATE_A_FLK_TEMPLATE:
            return CREATE_A_FLK_DISPLAY;
        default:
            return 'Not implemented template';
    }
}

const groupTemplates = templatesToUpdate => {
    const groupedTemplates = {};
    forEach(templatesToUpdate, template => {
        const groupName = `${getTemplateTypeName(template.templateType)}${
            template.templateState ? `(${template.templateState})` : ''
        }`;
        if (!groupedTemplates[groupName]) {
            groupedTemplates[groupName] = [];
        }
        groupedTemplates[groupName].push(template);
    });
    return groupedTemplates;
};

const ConfirmUpdateTemplates = (templatesToUpdate, clearTemplates, updateTemplates, settingsTitle) => {
    const groupedTemplates = groupTemplates(templatesToUpdate.templates);
    const confirmDialog = ({ onClose }) => {
        const handleClickedNo = () => {
            clearTemplates();
            onClose();
        };
        const handleClickedYes = () => {
            updateTemplates();
            onClose();
        };
        const haveDirtyFields = templatesToUpdate.dirtyFieldNames && templatesToUpdate.dirtyFieldNames.length > 0;
        return (
            <div className="react-confirm-alert-body confirm-update-templates-modal-body">
                <div className="templates-confirmation-header">
                    <h2>Saved</h2>
                    <CheckCircleSharpIcon />
                </div>
                <hr />
                <section>
                    {haveDirtyFields && (
                        <React.Fragment>
                            <p>
                                The changes made to the settings have been saved successfully. The following fields are
                                used in templates:
                            </p>
                            <div className="templates-confirmation-section-title">{settingsTitle}</div>
                            <ul className="templates-confirmation-updated-fields-list">
                                {templatesToUpdate.dirtyFieldNames.map((updatedField, index) => {
                                    return <li key={index}>{camelcaseToSpacedWords(updatedField)}</li>;
                                })}
                            </ul>
                        </React.Fragment>
                    )}
                </section>
                <section>
                    {haveDirtyFields && (
                        <p>
                            <strong>The following templates use this updated information:</strong>
                        </p>
                    )}
                    {!haveDirtyFields && (
                        <p>
                            <strong>
                                Your changes were saved. We have identified changes that affect the below Templates.
                            </strong>
                        </p>
                    )}
                    {Object.keys(groupedTemplates).map(templateGroup => {
                        return (
                            <div key={templateGroup}>
                                <p className="templates-confirmation-section-title">{templateGroup}</p>
                                <ul className="templates-confirmation-updated-fields-list">
                                    {groupedTemplates[templateGroup].map((template, index) => {
                                        return (
                                            <li key={index}>
                                                <div>{template.templateName}</div>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        );
                    })}
                </section>
                {haveDirtyFields && (
                    <React.Fragment>
                        <p>
                            Selecting "{BUTTON_TEXT_SETTINGS_AND_TEMPLATES}" will update all of the above templates with
                            the new data.
                        </p>
                        <p>
                            If you have custom templates for different entities you may want to update the templates
                            individually and choose "{BUTTON_TEXT_SETTINGS_ONLY}"
                        </p>
                    </React.Fragment>
                )}
                {!haveDirtyFields && (
                    <p>
                        <strong>Note:</strong> Please update your templates manually if your current changes to the
                        settings need to be applied.
                    </p>
                )}
                <div className="modal-footer">
                    <button className="button-default button-secondary" onClick={handleClickedNo}>
                        {haveDirtyFields ? BUTTON_TEXT_SETTINGS_ONLY : 'Confirm'}
                    </button>
                    {haveDirtyFields && (
                        <button className="button-default button-primary" onClick={handleClickedYes}>
                            {BUTTON_TEXT_SETTINGS_AND_TEMPLATES}
                        </button>
                    )}
                </div>
            </div>
        );
    };
    return confirmDialog;
};
export default ConfirmUpdateTemplates;
