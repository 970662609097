import axios from 'axios';
import cx from 'classnames';
import { cloneDeep, find, isEmpty } from 'lodash';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Form, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { useSelector } from 'react-redux';
import { FormEmail } from '../../../../../components/form/FormEmail';
import { FormPhone } from '../../../../../components/form/FormPhone';
import FormSection from '../../../../../components/form/FormSection';
import { SelectField } from '../../../../../components/form/FormSelect';
import { FormTextRegular } from '../../../../../components/form/FormText';
import ClientAcknowledgement from '../../../../../components/ClientAcknowledgement.tsx';

import ModalDialog from '../../../../../common/components/ModalDialog';
import { FormTextMultiline } from '../../../../../components/form/FormText';

import '../../../../../sass/FlkAPdfForm.scss';
import '../../../../../sass/noAddressDocumentModal.scss';

import { arrayMove } from '@dnd-kit/sortable';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';
import SmsSharpIcon from '@flk-mui-icons/ChatSharp';
import CheckSharpIcon from '@flk-mui-icons/CheckSharp';
import DoneIcon from '@flk-mui-icons/DoneSharp';
import EmailSharpIcon from '@flk-mui-icons/EmailSharp';
import GestureSharpIcon from '@flk-mui-icons/GestureSharp';
import PersonSharpIcon from '@flk-mui-icons/PersonSharp';
import { confirmAlert } from 'react-confirm-alert';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { LEASE_STATUS_DRAFT } from '../../../../../actions/dashboard';
import { NewDocumentHeader } from '../../../../../components/dashboard/InfoAgreementComponents/NewDocumentHeaderLeft';
import { FormRadioGroupButton } from '../../../../../components/form/FormRadioGroupButton';
import DummyContactSearch from './components/DummyContactSearch';
import {
    CONTACT_TYPE_AUTO_SAVE,
    DOCUMENT_CUSTOM,
    DOCUMENT_DELIVERY_TYPE_EMAIL,
    DOCUMENT_DELIVERY_TYPE_SMS,
    FLK_A_PDF_CREATION_DISPLAY,
    FLK_A_PDF_TEMPLATE,
    FORM_SUBMIT_TYPE_SAVE,
    FORM_SUBMIT_TYPE_SEND,
    PDF_GENERATE_WAIT_TIME
} from '../../../../../config';
import {
    ACKNOWLEDGE_CONFIRMATION_TYPE,
    BULK_SEND_APPROVAL_STATUS_DECLINED,
    BULK_SEND_APPROVAL_STATUS_PENDING,
    CLONE_TEMPLATE,
    EXECUTION_TYPE_ACKNOWLEDGEMENT_PAGE,
    EXECUTION_TYPE_EMBEDDED_SIGNATURE,
    ONBOARDING_TRACKING_PREFIX,
    PDF_GEN_COMPLETE,
    PDF_GEN_PROCESSING,
    SIGN_CONFIRMATION_TYPE,
    PUBLIC_URL_BUSINESS
} from '../../../../../constants/constants';
import { getDocumentEditMode, getPdfGeneratedState } from '../../../../../selectors/document';
import { getUserInfo } from '../../../../../selectors/user';
import { DEADLINE_OPTIONS } from '../../../../../utils/formUtils';
import {
    isDocumentEditMode,
    isDocumentReadOnlyMode,
    isDocumentStatusDraft,
    isDocumentStatusSentForSigning,
    isDocumentTemplateMode
} from '../../../../../utils/generalUtils';
import PdfViewer from '../../../../PdfViewer/PdfViewer';
import { FlkAPdfHelp } from '../../../help/FlkAPdfHelp';
import FlkAPdfFooterPublic from './FlkAPdfFooterPublic';
import UnevenSignaturesConfirmModal from '../UnevenSignaturesConfirmModal';
import UploadADocUpload from '../UploadADocUpload';
import DocumentTitleHeaderPublic from './components/DocumentTitleHeaderPublic';
import FlkAPdfCreatePublic from './FlkAPdfCreatePublic';

import styles from './FlkAPdfFormPublic.module.scss';
import CustomPlaceholderFormSection from '../CustomPlaceholderFormSection.tsx';
import { useUploadedDocumentStateItems } from '../../../../PdfViewer/components/useUploadedDocumentState.ts';
import {
    deleteAnnotationsForCustomPlaceholder,
    getCustomPlaceholderCountsAfterDeletingDocument,
    getDocumentsWithUpdatedAnnotations,
    reloadAnnotationsAfterDeletingClient
} from '../../../../PdfViewer/utils.ts';
import { SidebarAccordionItems } from '../../../../PdfViewer/components/Sidebar.tsx';
import { CustomPlaceholderRespondentType } from '../../../../../types/UploadADoc.ts';
import Tooltip from '../../../../../common/components/tooltips/Tooltip.tsx';

function FlkAPdfForm({ uploadADocId, setUploadADocId, openSignupModal }) {
    const SIGN = 'sign';
    const ACKNOWLEDGE = 'acknowledge';

    const CONFIRMATION_TEXT = 'I confirm I have read and reviewed the document(s)';

    const isOpenCustomDocumentForm = true;

    const documentEditMode = useSelector(getDocumentEditMode);
    const loggedInUser = useSelector(getUserInfo);
    const [currentDocument, setCurrentDocument] = useState(null);
    const history = useHistory();

    const pdfGeneratedState = useSelector(getPdfGeneratedState);

    const formRef = useRef(null);
    const formValuesRef = useRef(null);

    /**
     * These variables below are for the saving before closing functionality
     */
    const [isDirty, setIsDirty] = useState(false);
    const handleFormDirtyChange = form => {
        if (form.getState().dirty && !isDirty) {
            setIsDirty(true);
        }
    };
    const [creatingDocument, setCreatingDocument] = useState(false);
    const [documentCreated, setDocumentCreated] = useState(!!uploadADocId);
    const [isScrollToAnchor, setIsScrollToAnchor] = useState(false);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSavingForPdfViewer, setIsSavingForPdfViewer] = useState(false);
    const [isSubmitFail, setIsSubmitFail] = useState(false);
    const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);

    const [isSending, setIsSending] = useState(false);
    const [isSendSuccessful, setIsSendSuccessful] = useState(false);
    const [isSendFail, setIsSendFail] = useState(false);

    const [uploadedDocumentsList, setUploadedDocumentsList] = useState([]);
    const [backendError, setBackendError] = useState(null);

    const [backendErrors, setBackendErrors] = useState(false);

    const [documentUploadProgress, setDocumentUploadProgress] = useState(0);
    const [annotationsCount, setAnnotationsCount] = useState(null);

    const [formData, setFormData] = useState({});
    const [hasAnnotations, setHasAnnotations] = useState(false);
    const isReadOnly = false;

    const [isOpenConfirmAlert, setIsOpenConfirmAlert] = useState(false);

    const [contactList, setContactList] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState('');
    const [currentConfirmationType, setCurrentConfirmationType] = useState(undefined);
    const [currentExecutionType, setCurrentExecutionType] = useState(undefined);

    const [shouldShowPdfViewer, setShouldShowPdfViewer] = useState(false);

    const isCustomPlaceholdersDirtyRef = useRef(false);
    const [hasUpdatedCustomPlaceholdersSinceOpeningPdf, setHasUpdatedCustomPlaceholdersSinceOpeningPdf] =
        useState(false);

    const {
        uploadedDocumentStateItems,
        selectedUploadedDocId,
        setSelectedUploadedDocId,
        addUploadedDocumentStateItem,
        removeUploadedDocumentStateItem,
        resetVisitedState,
        handleUpdateAnnotationCount,
        defaultOpenSidebarItems,
        setDefaultOpenSidebarItems,
        setIsWebViewerInitialised,
        areAllDocumentsInitialised
    } = useUploadedDocumentStateItems();

    const CUSTOM_DOCUMENT_URL = `api/onboarding/custom-document/${uploadADocId || currentDocument?.id}`;

    useEffect(() => {
        if (uploadADocId) {
            axios.get(CUSTOM_DOCUMENT_URL).then(response => {
                setCurrentDocument(response.data.doc);
            });
        }

        if (!documentCreated) {
            history.push('?step=initial');
        }
    }, [uploadADocId, CUSTOM_DOCUMENT_URL, documentCreated]);

    const clearFormData = useCallback(() => {
        if (currentDocument) {
            if (currentDocument.requiresApproval) {
                if (
                    [BULK_SEND_APPROVAL_STATUS_PENDING, BULK_SEND_APPROVAL_STATUS_DECLINED].includes(
                        currentDocument.bulkSendApprovalStatus
                    )
                ) {
                }
            }
            if (currentDocument.hasAnnotations) {
                setHasAnnotations(true);
            }
            setUploadedDocumentsList(currentDocument.uploadedDocuments);
            if (currentDocument.annotationsCount) {
                setAnnotationsCount(JSON.parse(currentDocument.annotationsCount));
            }
            setDocumentCreated(!currentDocument.isCreatedFromTemplate);

            //set the execution type based on the documents uploaded from the create screen.
            if (!currentDocument.confirmationType) {
                if (currentDocument?.uploadedDocuments?.length > 1) {
                    setCurrentExecutionType(EXECUTION_TYPE_EMBEDDED_SIGNATURE);
                    setCurrentConfirmationType(SIGN_CONFIRMATION_TYPE);
                }
            }

            if (currentDocument.isCreatedFromTemplate && currentDocument.isCloneFromTemplate) {
                setSelectedTemplate({
                    value: currentDocument?.id,
                    label: currentDocument.templateName ? currentDocument.templateName : currentDocument.documentTitle,
                    documentTitle: currentDocument.documentTitle,
                    type: CLONE_TEMPLATE
                });
            }
        } else {
            setDocumentCreated(false);
            setUploadedDocumentsList([]);
        }
        let templateName;
        if (currentDocument && isDocumentTemplateMode(documentEditMode) && currentDocument.isCreatedFromTemplate) {
            templateName = currentDocument.templateName ? currentDocument.templateName : currentDocument.documentTitle;
        }

        let clientData;
        // if client was already saved in client document use that data
        if (currentDocument && currentDocument.client?.clients?.length > 0) {
            clientData = currentDocument.client.clients;
        } else {
            clientData = [{ name: '', email: '', phone: '', source: CONTACT_TYPE_AUTO_SAVE }];
        }

        setFormData(
            currentDocument
                ? {
                      documentTitle: currentDocument.isCreatedFromTemplate ? currentDocument.documentTitle : undefined,
                      descriptionText: currentDocument.descriptionText || 'Please review the attached document(s).',
                      clients: clientData,
                      deliveryType: currentDocument.deliveryType,
                      executionType: currentDocument.executionType
                          ? currentDocument.executionType
                          : currentExecutionType,
                      confirmationType: currentConfirmationType
                          ? currentConfirmationType
                          : currentDocument.confirmationType,
                      acknowledgementText: currentDocument.acknowledgementText || CONFIRMATION_TEXT,
                      deadline: `${currentDocument.deadline ? currentDocument.deadline : DEADLINE_OPTIONS[2].value}`, //has to be a string
                      signElectronically: true,
                      annotations: currentDocument.annotations,
                      annotationsCount: currentDocument.annotationsCount,
                      optimisedPdfFile: currentDocument.optimisedPdfFile,
                      templateName,
                      isBulkCreation: currentDocument.isBulkCreation,
                      contactsList: currentDocument.contactsList,
                      useContactList: currentDocument.useContactList,
                      customPlaceholders: currentDocument.customPlaceholders
                  }
                : {
                      descriptionText: `Please review the following document(s).`,
                      acknowledgementText: CONFIRMATION_TEXT,
                      deadline: DEADLINE_OPTIONS[2].value,
                      clients: clientData,
                      deliveryType: undefined,
                      confirmationType: undefined,
                      signElectronically: true,
                      useContactList: false
                  }
        );
    }, [currentDocument, loggedInUser, documentEditMode]);

    useEffect(() => {
        if (isScrollToAnchor) {
            scrollToAnchor();
        }
    }, [isScrollToAnchor]);

    useEffect(() => {
        if (currentDocument && isOpenCustomDocumentForm) {
            setIsScrollToAnchor(false);
            if (window.location.href.indexOf('#annotation-viewer-anchor') !== -1) {
                setIsScrollToAnchor(true);
            }
        }
    }, [isOpenCustomDocumentForm, currentDocument]);

    useEffect(() => {
        clearFormData();
    }, [clearFormData, isOpenCustomDocumentForm, loggedInUser]);

    const handlePdfViewerSaveSuccess = React.useCallback(
        updatedDocument => {
            resetVisitedState();
            setCurrentDocument(updatedDocument);
        },
        [resetVisitedState, setCurrentDocument]
    );

    const closePdfViewer = React.useCallback(() => {
        setShouldShowPdfViewer(false);
        setSelectedUploadedDocId(null);
        if (hasUpdatedCustomPlaceholdersSinceOpeningPdf) {
            setHasUpdatedCustomPlaceholdersSinceOpeningPdf(false);
        }
    }, [setShouldShowPdfViewer, setSelectedUploadedDocId, hasUpdatedCustomPlaceholdersSinceOpeningPdf]);

    const agencyDetailsForPdfViewer = React.useMemo(
        () => ({
            name: ''
        }),
        []
    );

    const closeModal = () => {
        window.open(PUBLIC_URL_BUSINESS, '_self');
    };

    const handleCloseModal = () => {
        if (isDirty) {
            confirmAlert({
                title: '',
                message: 'Leaving this page will discard your document. Are you sure you want to leave?',
                buttons: [
                    {
                        label: 'No',
                        onClick: () => {}
                    },
                    {
                        label: 'Yes',
                        onClick: () => {
                            closeModal();
                        }
                    }
                ]
            });
        } else {
            closeModal();
        }
    };

    function getFormValuesWithExecutionType(values, files) {
        const filesCount = files.length;
        let executionType;
        let confirmationType;
        if (values.confirmationType) {
            if (values.confirmationType === SIGN_CONFIRMATION_TYPE) {
                executionType = EXECUTION_TYPE_EMBEDDED_SIGNATURE;
                confirmationType = SIGN_CONFIRMATION_TYPE;
            } else {
                executionType = EXECUTION_TYPE_ACKNOWLEDGEMENT_PAGE;
                confirmationType = ACKNOWLEDGE_CONFIRMATION_TYPE;
            }
        } else {
            const existingFilesCount = currentDocument?.uploadedDocuments?.length ?? 0;
            if (existingFilesCount + filesCount > 1) {
                executionType = EXECUTION_TYPE_EMBEDDED_SIGNATURE;
                confirmationType = SIGN_CONFIRMATION_TYPE;
            } else {
                executionType = EXECUTION_TYPE_ACKNOWLEDGEMENT_PAGE;
                confirmationType = ACKNOWLEDGE_CONFIRMATION_TYPE;
            }
        }

        return {
            ...values,
            executionType,
            confirmationType
        };
    }

    function checkClientDetails(values) {
        const clients = values.clients;
        if (clients && clients.length > 0) {
            return clients.every(client => {
                return !isEmpty(client.email);
            });
        }
        return false;
    }

    const addAnnotationsForFile = function (file, files, values) {
        if (isDocumentTemplateMode(documentEditMode) || checkClientDetails(values)) {
            const data = getFormValuesWithExecutionType(values, files);
            setFormData(data);
            setIsDirty(true);
            if (files?.length > 0) {
                data.uploadedDocuments = files;
                return saveDocumentAndRedirectToAnnotationViewer(data, true, file);
            }
        } else {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className="react-confirm-alert-body delete-user-modal">
                            <p>Looks like you have forgotten to add some recipient details.</p>
                            <br />
                            <p>Please fill in recipient details above.</p>
                            <div className="react-confirm-alert-button-group">
                                <button
                                    type="button"
                                    onClick={() => {
                                        onClose();
                                    }}
                                >
                                    OK
                                </button>
                            </div>
                        </div>
                    );
                }
            });
        }
    };

    /**
     * Save deletion to the server
     * @param values
     * @param updatedFilesList
     * @returns {Promise<void>}
     */
    const deleteDocumentAndSave = async (form, updatedFilesList, deletedDocumentId) => {
        getCustomPlaceholderCountsAfterDeletingDocument(
            form,
            uploadedDocumentStateItems.find(item => item.uploadedDocumentId === deletedDocumentId)
        );

        const data = cloneDeep(form.getState().values);

        data.uploadedDocuments = updatedFilesList;
        if (updatedFilesList && updatedFilesList.length === 0) {
            setHasAnnotations(false);
            setAnnotationsCount(null);
        }
        setUploadedDocumentsList(updatedFilesList);
        removeUploadedDocumentStateItem(deletedDocumentId);
        await handleSaveForm(data);
    };

    const addDocumentsToListAndSave = async (files, values) => {
        // set the executionType type if one is not selected
        const data = getFormValuesWithExecutionType(values, files);
        const newDocumentsList = [...uploadedDocumentsList, ...uploadObjectsFromFiles(files)];

        data.uploadedDocuments = newDocumentsList;
        // Optimistically display new uploaded document list. In the event of failure to save form the document will appear
        // without a title link - the user will still be able to add placeholders from PDFTron.
        setUploadedDocumentsList(newDocumentsList);
        await handleSaveForm(data);
    };

    const reorderDocumentList = async (activeId, overId) => {
        setUploadedDocumentsList(documents => {
            const activeIndex = documents.findIndex(uploadedDocument => uploadedDocument.id === activeId);
            const overIndex = documents.findIndex(uploadedDocument => uploadedDocument.id === overId);
            return arrayMove(documents, activeIndex, overIndex);
        });

        if (!isDirty) {
            setIsDirty(true);
        }
    };

    /**
     * Omitting the uploadedDocumentId param will clear all annotations for all
     * uploadedDocuments
     * @param values
     * @param uploadedDocumentId
     * @returns {Promise<AxiosResponse<any>>}
     */
    async function handleClearSignaturePlaceholders(values, uploadedDocumentId) {
        setHasAnnotations(false);
        setAnnotationsCount(null);
        setIsSubmitting(true);
        const data = {
            ...values
        };
        let url = `${CUSTOM_DOCUMENT_URL}/remove-all-annotations`;
        if (uploadedDocumentId) {
            url = `${CUSTOM_DOCUMENT_URL}/${uploadedDocumentId}/remove-annotations`;
        }

        setFormData(data);
        // dispatch(setPdfGeneratedState(PDF_GEN_PENDING));
        return axios.post(url).then(response => {
            setIsSubmitting(false);
            if (response.data.customDocument) {
                // dispatch(setCurrentDocument(response.data.customDocument));
                setCurrentDocument(response.data.customDocument);
            }
            setTimeout(() => {
                // dispatch(setPdfGeneratedState(PDF_GEN_COMPLETE));
            }, PDF_GENERATE_WAIT_TIME);
            return response;
        });
    }

    //Iterate files and create uploadedDocumentObjects
    function uploadObjectsFromFiles(files) {
        const uploadedObjects = [];
        files.forEach(file => {
            uploadedObjects.push({ document: file });
        });
        return uploadedObjects;
    }

    function openPdfViewer(uploadedDoc) {
        setShouldShowPdfViewer(true);
        setSelectedUploadedDocId(uploadedDoc.id);
    }

    const saveDocumentAndRedirectToAnnotationViewer = async (data, bypassValidation = false, file = null) => {
        let url = `${CUSTOM_DOCUMENT_URL}`;
        if (bypassValidation) {
            url = url.concat(`?bypassFormValidation=1`);
        }
        if (data.uploadedDocuments && data.uploadedDocuments.length > 1) {
            data.executionType = EXECUTION_TYPE_EMBEDDED_SIGNATURE;
        }
        // Do not send phone numbers if delivery type is email
        if (!bypassValidation && data.deliveryType === DOCUMENT_DELIVERY_TYPE_EMAIL) {
            data.clients?.forEach(client => {
                client.phone = undefined;
            });
        }

        data.uploadedDocuments = await getDocumentsWithUpdatedAnnotations(
            data.uploadedDocuments,
            uploadedDocumentStateItems
        );

        data.pdfGeneratedState = PDF_GEN_PROCESSING;
        // dispatch(setPdfGeneratedState(PDF_GEN_PROCESSING));
        setIsSavingForPdfViewer(true);
        setBackendErrors(null);
        setIsSubmitFail(false);
        setIsSubmitSuccessful(false);
        setIsSendFail(false);
        return axios
            .post(url, data)
            .then(async response => {
                // dispatch(setCurrentDocument(response.data.customDocument));
                setCurrentDocument(response.data.customDocument);
                setIsSubmitting(false);
                setIsSubmitSuccessful(true);
                setIsSavingForPdfViewer(false);
                if (file) {
                    openPdfViewer(file);
                } else if (response.data.customDocument.confirmationType === ACKNOWLEDGE) {
                    openPdfViewer(response.data.customDocument.uploadedDocuments[0]);
                }
            })
            .catch(error => {
                setIsSavingForPdfViewer(false);
                setIsSubmitting(false);
                setIsSubmitSuccessful(false);
                setIsSubmitFail(true);
                setIsOpenConfirmAlert(false);
                setBackendErrors(getErrorFromResponse(error));
                //remove signature type as there was an error in validating, so we want them to select again
                if (!hasAnnotations) {
                    const newData = {
                        ...data,
                        executionType: undefined
                    };
                    setFormData(newData);
                }

                return false;
            });
    };

    function scrollToFirstError() {
        const firstErrorElement = document.querySelector('[data-has-error="true"]');

        if (firstErrorElement) {
            setTimeout(() => {
                firstErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
            }, 1000);
        }
    }

    const scrollToAnchor = () => {
        const element = document.getElementById('annotation-viewer-anchor');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        }
    };

    function getErrorFromResponse(error) {
        const response = error.response;
        if (response && response.data && response.data.errors) {
            let err = response.data.errors;
            if (err.client) {
                err.clients = err.client.clients;
            }
            return err;
        }
        return false;
    }

    async function sendForSigning(data) {
        // Do not send phone numbers if delivery type is email
        if (data.deliveryType === DOCUMENT_DELIVERY_TYPE_EMAIL) {
            data.clients?.forEach(client => {
                client.phone = undefined;
            });
        }
        setIsSendSuccessful(false);
        setIsSendFail(false);
        setIsSubmitFail(false);
        setIsSending(true);
        return axios
            .post(`${CUSTOM_DOCUMENT_URL}`, data)
            .then(response => {
                setIsDirty(false);
                setIsSending(false);
                setIsSendSuccessful(true);
                setIsSubmitSuccessful(false);
                history.push('?step=verify');
                openSignupModal();
                return true;
            })
            .catch(error => {
                setIsSending(false);
                setIsSendSuccessful(false);
                setIsSubmitSuccessful(false);
                setIsSendFail(true);
                setBackendErrors(getErrorFromResponse(error));
                setTimeout(() => {
                    scrollToFirstError();
                }, 1000);
                return false;
            });
    }

    async function handleSendForSigning(data) {
        data.uploadedDocuments = uploadedDocumentsList;

        data.uploadedDocuments = await getDocumentsWithUpdatedAnnotations(data.uploadedDocuments, annotationsCount, {
            removeHidden: true
        });

        if (
            data.confirmationType === SIGN &&
            (!hasAnnotations || !currentDocument.allDocsHaveClientSignatureAnnotations)
        ) {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className="react-confirm-alert-body delete-user-modal">
                            <p>Looks like you have forgotten to add signature placeholders on one of your documents.</p>
                            <br />
                            <p>At least one placeholder is needed per document.</p>
                            <div className="react-confirm-alert-button-group">
                                <button
                                    type="button"
                                    onClick={() => {
                                        onClose();
                                    }}
                                >
                                    OK
                                </button>
                            </div>
                        </div>
                    );
                }
            });
        } else {
            return sendForSigning(data);
        }
    }

    const allClientsHaveSameAmountOfSignatures = values => {
        if (values.isBulkCreation) {
            return true;
        } else {
            let haveSameAmountOfSignatures = true;
            if (annotationsCount) {
                let noOfSignatures = annotationsCount[0];
                values.clients.forEach((client, index) => {
                    if (noOfSignatures !== annotationsCount[index]) {
                        haveSameAmountOfSignatures = false;
                    }
                });
            }
            return haveSameAmountOfSignatures;
        }
    };

    const handleSubmit = (values, form) => {
        const data = cloneDeep(values);

        // add documents to values
        data.uploadedDocuments = uploadedDocumentsList;
        setDocumentUploadProgress(0);

        if (!hasAnnotations) {
            data.annotations = undefined;
        }

        if (values.submitType === FORM_SUBMIT_TYPE_SEND) {
            if (!allClientsHaveSameAmountOfSignatures(values)) {
                setIsOpenConfirmAlert(true);
            } else {
                return handleSendForSigning(data).finally(() => {
                    // reset to type save in case the user submits with enter
                    form.change('submitType', FORM_SUBMIT_TYPE_SAVE);
                    setIsDirty(false);
                });
            }
        } else {
            return handleSaveForm(data);
        }
    };

    async function handleSaveForm(data) {
        //Add the annotations
        setIsSubmitFail(false);
        setIsSubmitSuccessful(false);
        setIsSendFail(false);
        setBackendError(null);
        setIsSubmitting(true);

        data.uploadedDocuments = await getDocumentsWithUpdatedAnnotations(data.uploadedDocuments, annotationsCount);

        return axios
            .post(`${CUSTOM_DOCUMENT_URL}?bypassFormValidation=1`, data)
            .then(async response => {
                setIsSubmitting(false);
                setIsSubmitSuccessful(true);
                setIsDirty(false);
                setUploadedDocumentsList(response.data.customDocument.uploadedDocuments);
                setCurrentDocument(response.data.customDocument);
            })
            .catch(error => {
                setIsSubmitting(false);
                setIsSubmitSuccessful(false);
                setIsSubmitFail(true);
                return getErrorFromResponse(error);
            });
    }
    const removeClient = (values, client, index) => {
        if (client.id) {
            setIsSubmitting(true);
            const url = `${CUSTOM_DOCUMENT_URL}/remove-client/${client.id}`;
            axios.post(url).then(response => {
                setIsSubmitting(false);
                if (response.data.customDocument) {
                    reloadAnnotationsAfterDeletingClient(
                        response.data.customDocument.uploadedDocuments,
                        uploadedDocumentStateItems
                    );
                    setCurrentDocument(response.data.customDocument);
                }
            });
        } else {
            let newState = cloneDeep(values);
            setIsDirty(true);
            if (index === 0 && newState.clients && newState.clients.length === 1) {
                newState.clients[0] = { name: '', email: '', phone: '+61' };
                return setFormData(newState);
            }
            return setFormData({
                ...newState,
                clients: [...newState.clients.slice(0, index), ...newState.clients.slice(index + 1)]
            });
        }
    };

    function addNewEmptyClient(values) {
        const data = cloneDeep(values);
        // dispatch(setPdfGeneratedState(PDF_GEN_PENDING
        data.clients.push({
            name: '',
            email: '',
            Phone: '',
            source: CONTACT_TYPE_AUTO_SAVE
        });
        setFormData(data);
        setIsDirty(true);
    }

    function handleSearchSelect(result, values) {
        const data = cloneDeep(values);
        if (data.clients && data.clients[0] && data.clients[0].name === '') {
            data.clients[0] = {
                name: result.fullName || result.corporationName,
                email: result.email,
                phone: result.mobile,
                source: result.source
            };
        } else {
            data.clients.push({
                name: result.fullName || result.corporationName,
                email: result.email,
                phone: result.mobile,
                source: result.source
            });
        }
        setFormData(data);
        setIsDirty(true);
    }

    function getContactList(contactListId) {
        return find(contactList, { value: contactListId });
    }

    /**
     * Custom header on a modal
     * @returns {JSX.Element}
     */
    function customHeaderContent() {
        if (currentDocument && documentCreated) {
            let docType = DOCUMENT_CUSTOM;
            if (isDocumentTemplateMode(documentEditMode)) {
                docType = FLK_A_PDF_TEMPLATE;
            }
            return (
                <DocumentTitleHeaderPublic
                    disabled={isDocumentReadOnlyMode(documentEditMode)}
                    doc={currentDocument}
                    closeModal={handleCloseModal}
                    showHelpIcon={true}
                    helpComponent={<FlkAPdfHelp />}
                    isTemplate={isDocumentTemplateMode(documentEditMode)}
                    docType={docType}
                    showLockedIcon={false}
                    hideCloseButton={isSubmitting || isSending}
                    saveDoc={async newData => {
                        const data = { ...formValuesRef.current, ...newData };
                        data.uploadedDocuments = uploadedDocumentsList;

                        data.uploadedDocuments = getDocumentsWithUpdatedAnnotations(
                            data.uploadedDocuments,
                            annotationsCount
                        );

                        return axios.post(`${CUSTOM_DOCUMENT_URL}?bypassFormValidation=1`, data);
                    }}
                />
            );
        } else {
            return <NewDocumentHeader headerTitle={FLK_A_PDF_CREATION_DISPLAY} />;
        }
    }

    return (
        <React.Fragment>
            <ModalDialog
                isOpen={isOpenCustomDocumentForm}
                closeModal={handleCloseModal}
                shouldCloseOnOverlayClick={false}
                className={cx(
                    'document-centre-modal',
                    'document-modal-dialog',
                    'document-no-address-modal-dialog',
                    'custom-document',
                    'tenant'
                )}
                customHeader={true}
                containerClassName={styles.modalContainer}
                customHeaderContent={customHeaderContent}
                allowOverflow={true}
            >
                <Form
                    initialValues={formData}
                    validate={() => {
                        if (backendErrors) {
                            return backendErrors;
                        }
                    }}
                    onSubmit={handleSubmit}
                    mutators={{ ...arrayMutators }}
                >
                    {({ handleSubmit, values, form, dirtyFields }) => {
                        const numberOfFormClients = values.clients?.length ?? 0;
                        // set the values to the ref so that the close modal can access these values
                        const isCustomPlaceholdersDirty = !!dirtyFields.customPlaceholders;
                        if (
                            isCustomPlaceholdersDirty &&
                            isCustomPlaceholdersDirtyRef.current !== isCustomPlaceholdersDirty
                        ) {
                            isCustomPlaceholdersDirtyRef.current = isCustomPlaceholdersDirty;
                        }

                        const hasSignerPlaceholders = values.customPlaceholders?.some(
                            placeholder => placeholder.respondentType === CustomPlaceholderRespondentType.CLIENT
                        );

                        const shouldDisableAddingRecipients = hasSignerPlaceholders && values.clients.length > 0;
                        return (
                            <>
                                {currentDocument && (
                                    <>
                                        <PdfViewer
                                            isOpen={shouldShowPdfViewer}
                                            close={closePdfViewer}
                                            customDoc={currentDocument}
                                            selectedUploadedDocId={selectedUploadedDocId}
                                            setSelectedUploadedDocId={setSelectedUploadedDocId}
                                            agencyDetails={agencyDetailsForPdfViewer}
                                            onSaveSuccess={handlePdfViewerSaveSuccess}
                                            isOnboarding
                                            uploadedDocumentStateItems={uploadedDocumentStateItems}
                                            onCreateWebviewerInstance={addUploadedDocumentStateItem}
                                            onUpdateAnnotationCount={handleUpdateAnnotationCount}
                                            defaultOpenSidebarItems={defaultOpenSidebarItems}
                                            numberOfFormClients={numberOfFormClients}
                                            onWebViewerInitialised={uploadedDocumentId =>
                                                setIsWebViewerInitialised(uploadedDocumentId, true)
                                            }
                                        />
                                        {isSavingForPdfViewer && (
                                            <div className="pdftron-loader">
                                                <div className="pdftron-loader__spinner" />
                                            </div>
                                        )}
                                    </>
                                )}
                                <form
                                    noValidate
                                    onSubmit={values => handleSubmit(values)}
                                    id="create-custom-doc-form"
                                    data-automation-id={
                                        documentCreated ? `${ONBOARDING_TRACKING_PREFIX}-form` : undefined
                                    }
                                    ref={formRef}
                                >
                                    <FormSpy
                                        subscription={{ values: true }}
                                        onChange={() => handleFormDirtyChange(form)}
                                    />
                                    {documentCreated && (
                                        <React.Fragment>
                                            <br />
                                            {isReadOnly === false && (
                                                <FormRadioGroupButton
                                                    label="Delivery type"
                                                    labelClassName="custom-document__subheading"
                                                    name="deliveryType"
                                                    data={[
                                                        {
                                                            label: 'SMS',
                                                            value: DOCUMENT_DELIVERY_TYPE_SMS,
                                                            icon: SmsSharpIcon
                                                        },
                                                        {
                                                            label: 'Email',
                                                            value: DOCUMENT_DELIVERY_TYPE_EMAIL,
                                                            icon: EmailSharpIcon
                                                        }
                                                    ]}
                                                    required={true}
                                                    validateOnTouch={false}
                                                    value={values.deliveryType}
                                                />
                                            )}
                                            {/* Hide section if the agreement is in (awaiting_completion, complete) or ready only mode */}
                                            {isReadOnly === false && (
                                                <div
                                                    data-tip={
                                                        hasAnnotations
                                                            ? 'Please remove the signature placeholders below to change this.'
                                                            : undefined
                                                    }
                                                    data-for="confirmation-type-tooltip"
                                                >
                                                    <FormRadioGroupButton
                                                        label={'Acknowledgement type'}
                                                        labelClassName="custom-document__subheading"
                                                        name="confirmationType"
                                                        data={[
                                                            {
                                                                label: 'Sign',
                                                                value: SIGN,
                                                                icon: GestureSharpIcon,
                                                                disabled: hasAnnotations,
                                                                onClick: () => setCurrentConfirmationType(SIGN),
                                                                tooltipText:
                                                                    'This can be used to send multiple docs at one time',
                                                                showTooltip: !hasAnnotations
                                                            },
                                                            {
                                                                label: 'Acknowledge',
                                                                value: ACKNOWLEDGE,
                                                                icon: CheckSharpIcon,
                                                                disabled:
                                                                    hasAnnotations || uploadedDocumentsList?.length > 1,
                                                                onClick: () => setCurrentConfirmationType(ACKNOWLEDGE),
                                                                tooltipText:
                                                                    'This can be used for sending only one document at a time',
                                                                showTooltip: !hasAnnotations
                                                            }
                                                        ]}
                                                        required={true}
                                                        validateOnTouch={false}
                                                        value={values.confirmationType}
                                                    />
                                                </div>
                                            )}
                                            {hasAnnotations && (
                                                <ReactTooltip
                                                    id="confirmation-type-tooltip"
                                                    class="tooltip"
                                                    place="bottom"
                                                    effect="solid"
                                                    globalEventOff="wheel"
                                                />
                                            )}
                                            {currentDocument &&
                                                (isDocumentStatusDraft(currentDocument.status) ||
                                                    isDocumentStatusSentForSigning(currentDocument.status)) && (
                                                    <React.Fragment>
                                                        <div className="flex space-between">
                                                            <h2 className="custom-document__subheading">
                                                                'Recipient details'
                                                            </h2>

                                                            <div className="use-contact-list-container"></div>
                                                        </div>
                                                        {values.useContactList && (
                                                            <React.Fragment>
                                                                <p className="form-label">Contacts list:</p>
                                                                <SelectField
                                                                    name="contactsList"
                                                                    className="select-contact-list"
                                                                    options={contactList}
                                                                    disabled={values.disabled || hasAnnotations}
                                                                    isClearable={true}
                                                                    onChange={selectedContactList => {
                                                                        changeSelectedContactList(
                                                                            selectedContactList,
                                                                            values
                                                                        );
                                                                    }}
                                                                />
                                                                <FormRadioGroupButton
                                                                    name="additionalChargeConfirmationValue"
                                                                    label=""
                                                                    data={[
                                                                        {
                                                                            label: `Acknowledge and proceed`,
                                                                            value: 'true',
                                                                            icon: DoneIcon,
                                                                            onClick: () =>
                                                                                confirmAdditionalCharge(true, values)
                                                                        },
                                                                        {
                                                                            label: `Decline and add my contacts manually`,
                                                                            value: 'false',
                                                                            icon: PersonSharpIcon,
                                                                            onClick: () =>
                                                                                confirmAdditionalCharge(false, values)
                                                                        }
                                                                    ]}
                                                                    required={false}
                                                                    validateOnTouch={false}
                                                                    value={
                                                                        typeof values.isBulkCreation !== 'undefined'
                                                                            ? values.isBulkCreation.toString()
                                                                            : 'false'
                                                                    }
                                                                />
                                                                <br />
                                                                {isDocumentStatusDraft(currentDocument.status) &&
                                                                    values.contactsList &&
                                                                    getContactList(values.contactsList) && (
                                                                        <p>
                                                                            We will create a document for each of these{' '}
                                                                            {getContactList(values.contactsList).count}{' '}
                                                                            people from{' '}
                                                                            <a
                                                                                href={`/contact-list/${values.contactsList}`}
                                                                                target="blank"
                                                                            >
                                                                                {
                                                                                    getContactList(values.contactsList)
                                                                                        .label
                                                                                }
                                                                            </a>
                                                                        </p>
                                                                    )}
                                                            </React.Fragment>
                                                        )}
                                                        {(!values.useContactList ||
                                                            !isDocumentStatusDraft(currentDocument.status)) && (
                                                            <React.Fragment>
                                                                {currentDocument &&
                                                                    isDocumentStatusDraft(currentDocument.status) && (
                                                                        <DummyContactSearch
                                                                            searchBarClassName="contact-search"
                                                                            onSelect={result =>
                                                                                handleSearchSelect(result, values)
                                                                            }
                                                                            allowStaffSearch={true}
                                                                            contactType={DOCUMENT_CUSTOM}
                                                                        />
                                                                    )}
                                                                <br />
                                                                <div className="client-section">
                                                                    {values.clients.map((client, index) => (
                                                                        <FormSection
                                                                            key={index}
                                                                            title={`Recipient ${index + 1}`}
                                                                            showRemoveButton={!isReadOnly}
                                                                            removeButtonProps={{
                                                                                onClick: () => {
                                                                                    removeClient(values, client, index);
                                                                                },
                                                                                label: 'Remove',
                                                                                loading: isSubmitting
                                                                            }}
                                                                        >
                                                                            <hiddenInput name="source" />
                                                                            <FormTextRegular
                                                                                name={`clients[${index}].name`}
                                                                                label={'Name of recipient'}
                                                                                disabled={isReadOnly}
                                                                                required
                                                                            />
                                                                            <FormEmail
                                                                                name={`clients[${index}].email`}
                                                                                label="Email"
                                                                                disabled={
                                                                                    isReadOnly &&
                                                                                    currentDocument.status !==
                                                                                        LEASE_STATUS_DRAFT
                                                                                }
                                                                                required
                                                                            />
                                                                            {values.deliveryType ===
                                                                                DOCUMENT_DELIVERY_TYPE_SMS && (
                                                                                <FormPhone
                                                                                    name={`clients[${index}].phone`}
                                                                                    className={`clients-${index}-phone`}
                                                                                    label="Phone"
                                                                                    disabled={
                                                                                        isReadOnly &&
                                                                                        currentDocument.status !==
                                                                                            LEASE_STATUS_DRAFT
                                                                                    }
                                                                                    required
                                                                                />
                                                                            )}
                                                                        </FormSection>
                                                                    ))}
                                                                    {isDocumentEditMode(documentEditMode) &&
                                                                        values.clients &&
                                                                        values.clients.length < 4 && (
                                                                            <div className="button" data-tip={true}>
                                                                                <button
                                                                                    disabled={
                                                                                        isReadOnly ||
                                                                                        shouldDisableAddingRecipients
                                                                                    }
                                                                                    data-tip="You have used placeholders for the recipient to enter information. This feature is currently only available with 1 recipient."
                                                                                    data-for="signer-placeholders-tooltip"
                                                                                    className={cx(
                                                                                        'add-client',
                                                                                        styles.addClientButton
                                                                                    )}
                                                                                    type="button"
                                                                                    onClick={() => {
                                                                                        addNewEmptyClient(values);
                                                                                    }}
                                                                                >
                                                                                    Add recipient
                                                                                </button>
                                                                                {shouldDisableAddingRecipients && (
                                                                                    <Tooltip
                                                                                        id="signer-placeholders-tooltip"
                                                                                        place="bottom"
                                                                                        effect="solid"
                                                                                    />
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                </div>
                                                            </React.Fragment>
                                                        )}
                                                        <br />

                                                        <h2 className="custom-document__subheading">
                                                            Document Introduction Statement
                                                        </h2>
                                                        <FormTextMultiline
                                                            name="descriptionText"
                                                            label="Introduce your document"
                                                            disabled={isReadOnly}
                                                            isChatGpt={true}
                                                            gpt_fieldName="descriptionText"
                                                            gpt_isDocument={true}
                                                            gpt_documentId={currentDocument?.id}
                                                        />

                                                        {!isReadOnly && (
                                                            <div className="document-upload-container">
                                                                <h2 className="custom-document__subheading">
                                                                    Upload file
                                                                </h2>
                                                                <UploadADocUpload
                                                                    isSubmitting={isSubmitting}
                                                                    values={values}
                                                                    clients={values.clients}
                                                                    setDocumentUploadProgress={
                                                                        setDocumentUploadProgress
                                                                    }
                                                                    uploadEndPoint={`api/onboarding/custom-document/${currentDocument?.id}/upload-documents`}
                                                                    viewDocument={docId =>
                                                                        window.open(
                                                                            `/reader/view-onboarding-uploaded-document/${currentDocument?.id}/${docId}`,
                                                                            '_blank'
                                                                        )
                                                                    }
                                                                    deleteDocument={(
                                                                        updatedFilesList,
                                                                        deletedDocumentId
                                                                    ) => {
                                                                        deleteDocumentAndSave(
                                                                            form,
                                                                            updatedFilesList,
                                                                            deletedDocumentId
                                                                        );
                                                                    }}
                                                                    addDocuments={files => {
                                                                        addDocumentsToListAndSave(files, values);
                                                                    }}
                                                                    reorderDocumentsList={reorderDocumentList}
                                                                    addAnnotationsForFile={(file, files) => {
                                                                        setDefaultOpenSidebarItems([
                                                                            SidebarAccordionItems.CLIENTS,
                                                                            SidebarAccordionItems.DOCUMENTS,
                                                                            SidebarAccordionItems.CUSTOM_PLACEHOLDERS
                                                                        ]);
                                                                        addAnnotationsForFile(file, files, values);
                                                                    }}
                                                                    handleClearSignaturePlaceholders={uploadedDocumentId => {
                                                                        handleClearSignaturePlaceholders(
                                                                            values,
                                                                            uploadedDocumentId
                                                                        );
                                                                    }}
                                                                    handleClearSignaturePlaceholdersOnAllDocs={() =>
                                                                        handleClearSignaturePlaceholders(values)
                                                                    }
                                                                    uploadedDocuments={uploadedDocumentsList}
                                                                    backendError={backendError}
                                                                    documentUploadProgress={documentUploadProgress}
                                                                    hideUploader={isReadOnly}
                                                                    disabled={
                                                                        values.confirmationType ===
                                                                            ACKNOWLEDGE_CONFIRMATION_TYPE &&
                                                                        uploadedDocumentsList.length >= 1
                                                                    }
                                                                    disabledTooltipText="Can only send one file when using the acknowledge page"
                                                                />
                                                            </div>
                                                        )}
                                                        {values.confirmationType !== ACKNOWLEDGE_CONFIRMATION_TYPE && (
                                                            <CustomPlaceholderFormSection
                                                                canOpenPdfViewer={!!uploadedDocumentsList.length}
                                                                handleOpenPdfViewer={() => {
                                                                    setDefaultOpenSidebarItems(
                                                                        SidebarAccordionItems.CUSTOM_PLACEHOLDERS
                                                                    );
                                                                    addAnnotationsForFile(
                                                                        uploadedDocumentsList[0],
                                                                        uploadedDocumentsList,
                                                                        values
                                                                    );
                                                                }}
                                                                isReadOnly={isReadOnly}
                                                                deleteAnnotationsForCustomPlaceholder={customPlaceholderId => {
                                                                    deleteAnnotationsForCustomPlaceholder(
                                                                        customPlaceholderId,
                                                                        uploadedDocumentStateItems
                                                                    );
                                                                }}
                                                                showCustomPlaceholderWarning={
                                                                    hasUpdatedCustomPlaceholdersSinceOpeningPdf
                                                                }
                                                                onEditCustomPlaceholderValues={count => {
                                                                    if (
                                                                        !hasUpdatedCustomPlaceholdersSinceOpeningPdf &&
                                                                        count > 0
                                                                    ) {
                                                                        setHasUpdatedCustomPlaceholdersSinceOpeningPdf(
                                                                            true
                                                                        );
                                                                    }
                                                                }}
                                                            />
                                                        )}
                                                        <div id="annotation-viewer-anchor" />
                                                    </React.Fragment>
                                                )}
                                            <ClientAcknowledgement
                                                shouldShowMandatoryStatement={values.confirmationType === SIGN}
                                                isReadOnly={isReadOnly}
                                                className="custom-document__clientAcknowledgementContainer"
                                            />
                                            <FlkAPdfFooterPublic
                                                form={form}
                                                values={values}
                                                documentsList={uploadedDocumentsList}
                                                isSubmitting={isSubmitting}
                                                isSubmitFail={isSubmitFail}
                                                isSubmitSuccessful={isSubmitSuccessful}
                                                setBackendErrors={setBackendErrors}
                                                isSending={isSending}
                                                isSendFail={isSendFail}
                                                isSendSuccessful={isSendSuccessful}
                                                setIsSendSuccessful={setIsSendSuccessful}
                                                pdfGenerated={pdfGeneratedState || PDF_GEN_COMPLETE}
                                                areAllDocumentsInitialised={areAllDocumentsInitialised}
                                            />
                                        </React.Fragment>
                                    )}
                                    {isOpenConfirmAlert && (
                                        <UnevenSignaturesConfirmModal
                                            values={values}
                                            isOpen={isOpenConfirmAlert}
                                            closeModal={() => setIsOpenConfirmAlert(false)}
                                            annotationsCount={annotationsCount}
                                            isTemplate={isDocumentTemplateMode(documentEditMode)}
                                            isSubmitting={isSubmitting}
                                            isSending={isSending}
                                            onEdit={() => {
                                                const data = cloneDeep(values);
                                                // add documents to values
                                                data.uploadedDocuments = uploadedDocumentsList;
                                                setIsSubmitting(true);
                                                return saveDocumentAndRedirectToAnnotationViewer(
                                                    data,
                                                    false,
                                                    data.uploadedDocuments[0]
                                                );
                                            }}
                                            onSave={() => {
                                                const data = cloneDeep(values);
                                                return handleSendForSigning(data).finally(() => {
                                                    // reset to type save in case the user submits with enter
                                                    form.change('submitType', FORM_SUBMIT_TYPE_SAVE);
                                                    setIsDirty(false);
                                                    setIsOpenConfirmAlert(false);
                                                });
                                            }}
                                        />
                                    )}
                                </form>
                            </>
                        );
                    }}
                </Form>
                {!documentCreated && (
                    <FlkAPdfCreatePublic
                        closeModal={closeModal}
                        creatingDocument={creatingDocument}
                        setDocumentCreated={setDocumentCreated}
                        setCreatingDocument={setCreatingDocument}
                        setCustomDocument={setCurrentDocument}
                        setUploadADocId={setUploadADocId}
                        isTemplate={false}
                    />
                )}
            </ModalDialog>
        </React.Fragment>
    );
}
export default memo(FlkAPdfForm);
