import React, { useState } from 'react';
import ModalDialog from '../../../common/components/ModalDialog';
import ListIcon from '@flk-mui-icons/ListSharp';
import MessageSendingFailed from '../../../../assets/images/icons/send/MessageSendingFailed.svg';
import VisibilitySharpIcon from '@flk-mui-icons/VisibilitySharp';
import '../../../sass/dashboard/keyReminder.scss';
import '../../../sass/flkAKeyForm.scss';

const NO_RESPONSE = 'no-response';

const OpenReminderHistoryButton = ({ flkAKey, isEyeIcon }) => {
    const [isOpenReminderHistory, setIsOpenReminderHistory] = useState(false);

    const changeIsOpenReminderHistory = (e, value) => {
        e.stopPropagation();
        setIsOpenReminderHistory(value);
    };

    return (
        <React.Fragment>
            {isEyeIcon && <VisibilitySharpIcon onClick={e => changeIsOpenReminderHistory(e, true)} />}
            {!isEyeIcon && <ListIcon onClick={e => changeIsOpenReminderHistory(e, true)} />}
            {isOpenReminderHistory && (
                <ModalDialog
                    title="Reminder history"
                    isOpen={isOpenReminderHistory}
                    closeModal={e => changeIsOpenReminderHistory(e, false)}
                    shouldCloseOnOverlayClick={false}
                    className="document-centre-modal"
                >
                    <div className="reminder-module">
                        {Array.isArray(flkAKey.reminders) &&
                            flkAKey.reminders.map((item, index) => (
                                <div className="reminder" key={index}>
                                    {item && !item.responseReceivedAt && (
                                        <div className="reminder-group">
                                            <span
                                                className={`reminder-status reminder-status-light reminder-status-pending`}
                                            />
                                            <div className="reminder-details">
                                                <h2>
                                                    {item.name}({item.mobile})
                                                </h2>
                                                <table>
                                                    <tr>
                                                        <td>Sent</td>
                                                        <td>{item.sentFormatted}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Status</td>
                                                        <td>{item.borrowerResponse}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    )}
                                    {item && item.responseReceivedAt && (
                                        <div className="reminder-group">
                                            <span
                                                className={`reminder-status reminder-status-light reminder-status-${item.response}`}
                                            >
                                                {item.response === NO_RESPONSE && (
                                                    <img src={MessageSendingFailed} alt="No response" />
                                                )}
                                            </span>
                                            <div className="reminder-details">
                                                <h2>
                                                    {item.name}({item.mobile})
                                                </h2>
                                                <table>
                                                    <tr>
                                                        <td>Sent</td>
                                                        <td>{item.sentFormatted}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Response</td>
                                                        <td>{item.borrowerResponse}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Date of response</td>
                                                        <td>{item.responseReceivedAtFormatted}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))}
                    </div>
                </ModalDialog>
            )}
        </React.Fragment>
    );
};

export default OpenReminderHistoryButton;
